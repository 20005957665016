import { Button, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select2 } from '@blueprintjs/select';
import moment from 'moment-timezone';
import React from 'react';

interface Props {
  selectedItem: string | null;
  handleSelect: (item: string) => void;
}

const TZ_NAMES = moment.tz.names();

const filterTz: ItemPredicate<string> = (query, tzName, _index, exactMatch) => {
  const normalizedTitle = tzName.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  console.log(tzName, query);

  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return `${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
  }
};

const renderTz: ItemRenderer<string> = (tzName, { handleClick, handleFocus, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  const offset = `UTC${moment.tz(tzName).format('Z')}`;

  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={tzName}
      label={offset}
      onClick={handleClick}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={tzName}
    />
  );
};

export const TimezoneSelect: React.FC<Props> = ({ selectedItem, handleSelect }: Props) => {
  return (
    <Select2<string>
      items={TZ_NAMES}
      itemPredicate={filterTz}
      itemRenderer={renderTz}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
      onItemSelect={handleSelect}
    >
      <Button
        text={maybeRenderSelectedItem(selectedItem) || 'Select a timezone'}
        rightIcon="double-caret-vertical"
        placeholder="Select a timezone"
      />
    </Select2>
  );
};

function maybeRenderSelectedItem(selectedItem: string | null) {
  if (!selectedItem) {
    return undefined;
  }

  const offset = `UTC${moment.tz(selectedItem).format('Z')}`;

  return selectedItem ? `${selectedItem} (${offset})` : undefined;
}
