import { Intent, Pre } from '@blueprintjs/core';
import { useCallback, useEffect, useState } from 'react';

import axios from '../../../shared/custom-axios';
import { AppToaster } from '../../../shared/app-toaster';
import { MarketAvailabilityRule } from '../../../shared/interfaces/schedule';

interface Props {
  market_availability_rules: MarketAvailabilityRule[];
  exceptional_currencies: string[];
}

export function CoinsDiffsList({ market_availability_rules, exceptional_currencies: exceptional_symbols }: Props) {
  const [items, setItems] = useState<string[]>([]);

  const handleGetProjectedUpcomingRuns = useCallback(() => {
    let params = {
      market_availability_rules: market_availability_rules,
      exceptional_currencies: exceptional_symbols,
    };

    axios
      .post('/api/scheduled_tasks/coins_diffs', params)
      .then(({ data }) => {
        const { result } = data;
        setItems(result);
      })
      .catch((error) => {
        setItems([]);
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 15000 });
      });
  }, [market_availability_rules]);

  useEffect(() => {
    handleGetProjectedUpcomingRuns();
  }, []);

  return (
    <div>
      <Pre className="whitespace-pre-wrap overflow-auto h-96">
        <div className="grid grid-cols-3 hover:bg-gray-300 mb-1">
          <span>No.</span>
          <span>Symbol</span>
        </div>

        {items.map((symbol, index) => {
          return (
            <div className="grid grid-cols-3 hover:bg-gray-200">
              <span>{index + 1}</span>
              <span>{symbol}</span>
            </div>
          );
        })}
      </Pre>
    </div>
  );
}
