import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { Button, FormGroup, HTMLSelect, Icon, Intent, NumericInput, Switch, Tab, Tabs, Tag } from '@blueprintjs/core';

import {
  Bot,
  WickCatcherHighLowPauseSettings,
  WickCatcher,
  WickCatcherExitSettings,
  WickCatcherMarketTrackingSettings,
  WickCatcherSettingsTemplate,
  WickCatcherTierSettings,
  WickCatcherStrategyType,
} from '../../../shared/interfaces/bot';
import axios from '../../../shared/custom-axios';
import { AppToaster } from '../../../shared/app-toaster';
import { BotsManagerContext } from '../../../contexts/bots-manager';
import { TradeSideFormState } from '../../trading-pair/trade-side/form';
import { initOpportunitySettingFormData, OpportunitySettingForm, OpportunitySettingFormState } from './opportunity-setting-form';
import { AccountInstrument } from '../../common/account-instrument-select/account-instrument-select';
import { PrimaryExitSettingsForm } from './primary-exit-form';
import { WickCatcherSettingsTemplateForm } from './settings-template-form';
import { Pair, PairsSelect } from '../../common/pairs-select/pairs-select';
import { HighLowPauseSettingsForm } from './high-low-pause-settings-form';

export const TIMEFRAMES_IN_MINUTES = [
  { text: '1m', value: 1 },
  { text: '3m', value: 3 },
  { text: '5m', value: 5 },
  { text: '15m', value: 15 },
  { text: '30m', value: 30 },
  { text: '1h', value: 60 },
  { text: '4h', value: 240 },
  { text: '1d', value: 1440 },
];

export const STRATEGY_TYPES = [
  { text: 'Standard', value: 'standard' },
  { text: 'Instant Arb', value: 'instant_arb' },
];

let DEFAULT_MARKET_TRACKING_SETTINGS: WickCatcherMarketTrackingSettings = {
  debounce: 1000,
  overall_time_frame: 60,
  current_time_frame: 1,
  movement_time_frame: 15,
};

let DEFAULT_EXIT_SETTINGS: WickCatcherExitSettings = {
  percentage: 1,
  max_individual: 10000,
  debounce_amendment: 10000,
  movement_index: 1,
  tier: 10,
  movement_time_frame: 1,

  activate_ratio_distance: 50,
  implied_ratio_multiplier: 20,
  ec_ratio_change: 200,
  ratio_tick_size: 3,
  implied_volume_multiplier: 10,
  ec_replacement_debounce: 10000,
  disable_immediately_exit: true,
  secondary_hedge_tier: 1,
};

let DEFAULT_TIER_SETTINGS: WickCatcherTierSettings = {
  tier: 0,
  trigger_threshold: 0,
  short_multiplier: 1,
  long_multiplier: 1,
};

const DEFAULT_HIGH_LOW_PAUSE_SETTINGS: WickCatcherHighLowPauseSettings = {
  overall_enabled: false,
  overall_timeframe: '1w',
  overall_sensitivity: undefined,
  overall_pause_check_debounce: 10000,
  overall_resume_check_debounce: 600000,
  primary_enabled: false,
  primary_timeframe: '1d',
  primary_sensitivity: undefined,
  primary_pause_check_debounce: 10000,
  primary_resume_check_debounce: 600000,
};

interface PairFormState {
  id: number | undefined;
  primary: TradeSideFormState;
  secondary: TradeSideFormState;
}

export interface WickCatcherFormState {
  id?: number;
  name: string;
  description: string;
  strategy_type: WickCatcherStrategyType;
  exit_disabled: boolean;
  instant_secondary_hedge: boolean;
  short_mode: string;
  long_mode: string;
  currency: string;
  debounce: number | undefined;
  quantity: number | undefined;
  min_target: string;
  emergency_cancel: string;
  max_individual: number | undefined;
  distance: number | undefined;
  tp_delay: number | undefined;
  quota: number | undefined;
  market_tracking_settings: WickCatcherMarketTrackingSettings;
  exit_settings: WickCatcherExitSettings;
  overall_volume_tiers: WickCatcherTierSettings[];
  specific_volume_tiers: WickCatcherTierSettings[];
  overall_movement_tiers: WickCatcherTierSettings[];
  specific_movement_tiers: WickCatcherTierSettings[];
  pairs: PairFormState[];
  opportunity_settings: OpportunitySettingFormState[];
  high_low_pause_settings: WickCatcherHighLowPauseSettings;
  bot_id: number | undefined;
}

// TODO: Ugly code
function initFormData(bot: Bot | null | undefined, item: WickCatcher | null | undefined): WickCatcherFormState {
  if (item) {
    const opportunitySettingForms = item.opportunity_settings.map((setting) => initOpportunitySettingFormData(setting, `${setting.id}`));
    opportunitySettingForms.sort((o1, o2) => {
      if (o1.id && o2.id) {
        return o1.id - o2.id;
      } else {
        return o1.key.localeCompare(o2.key);
      }
    });
    return {
      id: item.id,
      name: item.name,
      description: item.description,
      strategy_type: item.strategy_type,
      exit_disabled: item.exit_disabled,
      instant_secondary_hedge: item.instant_secondary_hedge,
      short_mode: item.short_mode,
      long_mode: item.long_mode,
      currency: item.currency,
      debounce: item.debounce,
      quantity: item.quantity,
      min_target: String(item.min_target),
      emergency_cancel: String(item.emergency_cancel),
      max_individual: item.max_individual,
      distance: item.distance,
      tp_delay: item.tp_delay,
      quota: item.quota,
      market_tracking_settings: item.market_tracking_settings,
      exit_settings: item.exit_settings,
      overall_volume_tiers: item.overall_volume_tiers,
      specific_volume_tiers: item.specific_volume_tiers,
      overall_movement_tiers: item.overall_movement_tiers,
      specific_movement_tiers: item.specific_movement_tiers,
      opportunity_settings: opportunitySettingForms,
      high_low_pause_settings: item.high_low_pause_settings,
      pairs: item.pairs.map((pair) => {
        return {
          id: pair.id,
          primary: {
            main_exchange: pair.primary_instrument.main_exchange,
            sub_exchange: pair.primary_instrument.sub_exchange,
            instrument_id: String(pair.primary_instrument.id),
            order_book_id: pair.primary_order_book && String(pair.primary_order_book.id),
            account_id: String(pair.primary_account.id),
            private_stream_id: pair.primary_private_stream && String(pair.primary_private_stream.id),
            contract_stream_id: pair.primary_contract_stream && String(pair.primary_contract_stream.id),
          } as TradeSideFormState,
          secondary: {
            main_exchange: pair.secondary_instrument.main_exchange,
            sub_exchange: pair.secondary_instrument.sub_exchange,
            instrument_id: String(pair.secondary_instrument.id),
            order_book_id: pair.secondary_order_book && String(pair.secondary_order_book.id),
            account_id: String(pair.secondary_account.id),
            private_stream_id: pair.secondary_private_stream && String(pair.secondary_private_stream.id),
            contract_stream_id: pair.secondary_contract_stream && String(pair.secondary_contract_stream.id),
          } as TradeSideFormState,
        };
      }),
      bot_id: bot?.id,
    };
  }

  return {
    name: '',
    description: '',
    strategy_type: WickCatcherStrategyType.Standard,
    exit_disabled: false,
    instant_secondary_hedge: false,
    short_mode: 'stopped',
    long_mode: 'stopped',
    currency: '',
    debounce: undefined,
    quantity: undefined,
    min_target: '',
    emergency_cancel: '',
    max_individual: undefined,
    distance: undefined,
    tp_delay: 60000,
    quota: 1,
    market_tracking_settings: DEFAULT_MARKET_TRACKING_SETTINGS,
    exit_settings: DEFAULT_EXIT_SETTINGS,
    overall_volume_tiers: [DEFAULT_TIER_SETTINGS],
    specific_volume_tiers: [DEFAULT_TIER_SETTINGS],
    overall_movement_tiers: [DEFAULT_TIER_SETTINGS],
    specific_movement_tiers: [DEFAULT_TIER_SETTINGS],
    opportunity_settings: [],
    high_low_pause_settings: DEFAULT_HIGH_LOW_PAUSE_SETTINGS,
    pairs: [],
    bot_id: bot?.id,
  };
}

function initOpportunitySettingForm(index: number, side: string) {
  return {
    id: undefined,
    side: side,
    quantity: undefined,
    min_target: '',
    max_individual: undefined,
    emergency_cancel: '',
    distance: undefined,
    debounce: undefined,
    tp_delay: 60000,
    quota: 1,
    currency: 'USD',
    key: `New ${index}`,
    data: undefined,
  };
}

interface Props {
  item?: WickCatcher | null;
  setItem?: (item: WickCatcher | null) => void;
  onReloadItem?: (itemId: number) => void;
  afterCreatePair?: (wickCatcher: WickCatcher) => void;
}

export function WickCatcherForm({ item, setItem, onReloadItem, afterCreatePair }: Props) {
  const { currentBot } = useContext(BotsManagerContext);

  const initForm: WickCatcherFormState = initFormData(currentBot, item);
  const [formState, setFormState] = useState(initForm);
  const [isProcessing, setIsProcessing] = useState(false);
  const [validationError, setValidationError] = useState(new Map());

  const [editingPair, setEditingPair] = useState<Pair | undefined>();

  const [selectedPairs, setSelectedPairs] = useState<Pair[]>(
    item?.pairs.map((pair) => {
      return {
        id: pair.id,
        primary: { instrument: pair.primary_instrument, account: pair.primary_account },
        secondary: { instrument: pair.secondary_instrument, account: pair.secondary_account },
        inserted_at: pair.inserted_at,
      };
    }) || [],
  );

  const handleInputChange = useCallback((event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value }: any = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSwitchChange = useCallback((event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value }: any = event.currentTarget;

    setFormState((prevState: WickCatcherFormState) => {
      const key = name as keyof WickCatcherFormState;
      let currentValue = prevState[key] || false;

      return {
        ...prevState,
        [key]: !currentValue,
      };
    });
  }, []);

  const handleMarketTrackingSettingsInputChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.currentTarget;

      setFormState((prevState: WickCatcherFormState) => {
        return {
          ...prevState,
          market_tracking_settings: {
            ...prevState.market_tracking_settings,
            [name]: value,
          },
        };
      });
    },
    [],
  );

  const handleMarketTrackingSettingsNumberInputChange = useCallback((withCommas = false) => {
    return (valueAsNumber: number, valueAsString: string, inputElement: HTMLInputElement | null) => {
      if (inputElement) {
        const { name } = inputElement;

        setFormState((prevState: WickCatcherFormState) => {
          return {
            ...prevState,
            market_tracking_settings: {
              ...prevState.market_tracking_settings,
              [name]: withCommas ? Number(valueAsString.replace(/\D/g, '')) : valueAsString,
            },
          };
        });
      }
    };
  }, []);

  const handleExitSettingsInputChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.currentTarget;

      setFormState((prevState: WickCatcherFormState) => {
        return {
          ...prevState,
          exit_settings: {
            ...prevState.exit_settings,
            [name]: value,
          },
        };
      });
    },
    [],
  );

  const handleExitSettingsNumberInputChange = useCallback((withCommas = false) => {
    return (valueAsNumber: number, valueAsString: string, inputElement: HTMLInputElement | null) => {
      if (inputElement) {
        const { name } = inputElement;

        setFormState((prevState: WickCatcherFormState) => {
          return {
            ...prevState,
            exit_settings: {
              ...prevState.exit_settings,
              [name]: withCommas ? Number(valueAsString.replace(/\D/g, '')) : valueAsString,
            },
          };
        });
      }
    };
  }, []);

  const handleExitSettingsSwitchChange = useCallback((evt: any) => {
    const { name, checked } = evt.currentTarget;

    setFormState((prevState: WickCatcherFormState) => {
      return {
        ...prevState,
        exit_settings: {
          ...prevState.exit_settings,
          [name]: !checked,
        },
      };
    });
  }, []);

  const handleHighLowPauseSettingsInputChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.currentTarget;

      setFormState((prevState: WickCatcherFormState) => {
        return {
          ...prevState,
          high_low_pause_settings: {
            ...prevState.high_low_pause_settings,
            [name]: value,
          },
        };
      });
    },
    [],
  );

  const handleHighLowPauseSettingsNumberInputChange = useCallback((withCommas = false) => {
    return (valueAsNumber: number, valueAsString: string, inputElement: HTMLInputElement | null) => {
      if (inputElement) {
        const { name } = inputElement;

        setFormState((prevState: WickCatcherFormState) => {
          return {
            ...prevState,
            high_low_pause_settings: {
              ...prevState.high_low_pause_settings,
              [name]: withCommas ? Number(valueAsString.replace(/\D/g, '')) : valueAsString,
            },
          };
        });
      }
    };
  }, []);

  const handleHighLowPauseSettingsSwitchChange = useCallback((evt: any) => {
    const { name, checked } = evt.currentTarget;

    console.log(name, checked);

    setFormState((prevState: WickCatcherFormState) => {
      return {
        ...prevState,
        high_low_pause_settings: {
          ...prevState.high_low_pause_settings,
          [name]: checked,
        },
      };
    });
  }, []);

  const handleOpportunitySettingChange = (value: string | number, fieldName: string, settingId: string) => {
    setFormState((prevState) => {
      let index = prevState.opportunity_settings.findIndex((s) => s.key === settingId);
      let currentSetting = prevState.opportunity_settings[index];
      let updatedSetting = { ...currentSetting, [fieldName]: value };

      prevState.opportunity_settings[index] = updatedSetting;
      return prevState;
    });
  };

  const handleDeleteOpportunitySetting = (key: string) => {
    setFormState((prevState) => {
      let index = prevState.opportunity_settings.findIndex((setting) => setting.key == key);
      prevState.opportunity_settings.splice(index, 1);

      return {
        ...prevState,
        opportunity_settings: prevState.opportunity_settings,
      };
    });
  };

  const handleTierSettingNumberValueChange = (fieldName: keyof WickCatcherFormState, index: number) => {
    return (valueAsNumber: number, valueAsString: string, inputElement: HTMLInputElement | null) => {
      if (inputElement) {
        const { name } = inputElement;

        setFormState((prevState) => {
          let fieldData = prevState[fieldName] as WickCatcherTierSettings[];
          let currentTierSettings = fieldData[index];
          let newTierSettings = { ...currentTierSettings, [name]: valueAsString };
          fieldData[index] = newTierSettings;

          return {
            ...prevState,
            [fieldName]: fieldData,
          };
        });
      }
    };
  };

  const handleAddNewTierSetting = (fieldName: keyof WickCatcherFormState) => {
    setFormState((prevState) => {
      let fieldData = prevState[fieldName] as WickCatcherTierSettings[];
      let newTierSettings = DEFAULT_TIER_SETTINGS;

      return {
        ...prevState,
        [fieldName]: [...fieldData, ...[newTierSettings]],
      };
    });
  };

  const handleDeleteTierSetting = (fieldName: keyof WickCatcherFormState, index: number) => {
    setFormState((prevState) => {
      let fieldData = prevState[fieldName] as WickCatcherTierSettings[];
      fieldData.splice(index, 1);

      return {
        ...prevState,
        [fieldName]: fieldData,
      };
    });
  };

  const addNewOpportunitySetting = useCallback((side: string) => {
    return () =>
      setFormState((prevState) => {
        const newSettingIndexs = prevState.opportunity_settings
          .filter((setting) => !setting.id)
          .map((setting) => Number(setting.key.replace('New ', '')));
        console.log(newSettingIndexs);
        const maxIndex = newSettingIndexs.length > 0 ? Math.max(...newSettingIndexs) : 0;
        const newSetting = initOpportunitySettingForm(maxIndex + 1, side);

        return {
          ...prevState,
          opportunity_settings: [...prevState.opportunity_settings, newSetting],
        };
      });
  }, []);

  const formatTierSettings = (tierSettings: WickCatcherTierSettings[]) => {
    return tierSettings.map((setting) =>
      Object.assign({}, setting, {
        trigger_threshold: formatTierSetting(setting.trigger_threshold),
        short_multiplier: formatTierSetting(setting.short_multiplier),
        long_multiplier: formatTierSetting(setting.long_multiplier),
      }),
    );
  };

  const formatTierSetting = (value: number | string) => {
    if (typeof value === 'string') {
      return Number(value);
    } else {
      return value;
    }
  };

  useEffect(() => {
    let pairs = selectedPairs.map((pair) => {
      return {
        id: pair.id,
        primary: {
          main_exchange: pair.primary?.instrument.main_exchange,
          sub_exchange: pair.primary?.instrument.sub_exchange,
          instrument_id: String(pair.primary?.instrument.id),
          account_id: String(pair.primary?.account.id),
        } as TradeSideFormState,
        secondary: {
          main_exchange: pair.secondary?.instrument.main_exchange,
          sub_exchange: pair.secondary?.instrument.sub_exchange,
          instrument_id: String(pair.secondary?.instrument.id),
          account_id: String(pair.secondary?.account.id),
        } as TradeSideFormState,
      };
    });

    setFormState((prevState) => ({
      ...prevState,
      pairs: pairs,
      // primaries: [...prevState.primaries, newPrimary],
    }));
  }, [selectedPairs]);

  useEffect(() => {
    if (formState.strategy_type === WickCatcherStrategyType.Standard) {
      return setFormState((prevState) => {
        return {
          ...prevState,
          exit_disabled: false,
          instant_secondary_hedge: false,
        };
      });
    }

    if (formState.strategy_type === WickCatcherStrategyType.InstantArb) {
      return setFormState((prevState) => {
        return {
          ...prevState,
          exit_disabled: true,
          instant_secondary_hedge: true,
        };
      });
    }
  }, [formState.strategy_type]);

  const handleCreate = () => {
    if (validationError.size === 0) {
      setIsProcessing(true);

      console.log(formState, 'formState');

      const wickCatcherFormData = Object.assign({}, formState, {
        overall_volume_tiers: formatTierSettings(formState.overall_volume_tiers),
        specific_volume_tiers: formatTierSettings(formState.specific_volume_tiers),
        overall_movement_tiers: formatTierSettings(formState.overall_movement_tiers),
        specific_movement_tiers: formatTierSettings(formState.specific_movement_tiers),
      });

      console.log(wickCatcherFormData, 'wickCatcherFormData');

      axios
        .post<WickCatcher>(`/api/wick_catchers`, { wick_catcher: wickCatcherFormData })
        .then((res) => {
          if (afterCreatePair) {
            afterCreatePair(res.data);
          }
          const message = 'Wick Catcher created successfully!';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  };

  const handleUpdate = () => {
    if (validationError.size === 0) {
      setIsProcessing(true);

      const wickCatcherFormData = Object.assign({}, formState, {
        overall_volume_tiers: formatTierSettings(formState.overall_volume_tiers),
        specific_volume_tiers: formatTierSettings(formState.specific_volume_tiers),
        overall_movement_tiers: formatTierSettings(formState.overall_movement_tiers),
        specific_movement_tiers: formatTierSettings(formState.specific_movement_tiers),
      });

      axios
        .put<WickCatcher>(`/api/wick_catchers/${formState.id}`, { wick_catcher: wickCatcherFormData })
        .then(() => {
          setItem && setItem(null);
          item && onReloadItem && onReloadItem(item.id);

          const message = 'Wick Catcher updated successfully!';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  };

  const isRunning = (wickCatcher: WickCatcher | null | undefined): boolean => {
    if (!wickCatcher) {
      return false;
    }
    return wickCatcher.long_mode !== 'stopped' || wickCatcher.short_mode !== 'stopped';
  };

  const handleAddNext = useCallback((editingPair: Pair | undefined, newAccountInstrument: AccountInstrument, prioritySide?: string) => {
    console.log('handleAddNext');
    let newPair = { primary: undefined, secondary: undefined } as Pair;

    if (!editingPair) {
      addNewItem(newPair);
      setEditingPair(newPair);

      if (prioritySide) {
        updatePair(newPair, newAccountInstrument, prioritySide);
      } else {
        updatePair(newPair, newAccountInstrument, 'primary');
      }

      return;
    }

    if (!editingPair.primary && (!prioritySide || prioritySide === 'primary')) {
      updatePair(editingPair, newAccountInstrument, 'primary');

      return;
    }

    if (!editingPair.secondary && (!prioritySide || prioritySide === 'secondary')) {
      console.log(editingPair, 'editingPair');
      updatePair(editingPair, newAccountInstrument, 'secondary');

      return;
    }

    addNewItem(newPair);
    setEditingPair(newPair);

    if (prioritySide) {
      updatePair(newPair, newAccountInstrument, prioritySide);
    } else {
      updatePair(newPair, newAccountInstrument, 'primary');
    }
  }, []);

  const updatePair = (pair: Pair, accountInstrument: AccountInstrument, side: string) => {
    setSelectedPairs((prevState: Pair[]) => {
      let index = prevState.findIndex((s) => s === pair);

      if (index > -1) {
        let newPairs = [...prevState];
        let currentPair = prevState[index];
        let updatedPair = { ...currentPair, [side]: accountInstrument };
        setEditingPair(updatedPair);
        newPairs[index] = updatedPair;

        return newPairs;
      }

      return prevState;
    });
  };

  const removePair = (pair: Pair) => {
    setSelectedPairs((prevState: Pair[]) => {
      let index = prevState.findIndex((s) => s === pair);

      if (index > -1) {
        let newPairs = [...prevState];
        newPairs.splice(index, 1);

        return newPairs;
      }

      return prevState;
    });
  };

  const removePairSide = (pair: Pair, side: string) => {
    setSelectedPairs((prevState: Pair[]) => {
      let index = prevState.findIndex((s) => s === pair);

      if (index > -1) {
        let newPairs = [...prevState];
        let currentPair = prevState[index];
        let updatedPair = { ...currentPair, [side]: undefined };

        if (!updatedPair.primary && !updatedPair.secondary) {
          newPairs.splice(index, 1);
          return newPairs;
        }

        console.log(updatedPair, 'updatedPair');

        setEditingPair(updatedPair);
        newPairs[index] = updatedPair;

        return newPairs;
      }

      return prevState;
    });
  };

  const addNewItem = (pair: Pair) => {
    setSelectedPairs((prevState: Pair[]) => {
      return [...prevState, pair];
    });
  };

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    if (formState.bot_id !== currentBot.id) {
      setFormState((prevState) => ({
        ...prevState,
        bot_id: currentBot.id,
      }));
    }
  }, [currentBot, formState.bot_id]);

  const handleSelectTemplate = (template: WickCatcherSettingsTemplate | undefined) => {
    if (template) {
      setFormState((prevState) => ({
        ...prevState,
        market_tracking_settings: template.market_tracking_settings,
        exit_settings: template.exit_settings,
        overall_volume_tiers: template.overall_volume_tiers,
        specific_volume_tiers: template.specific_volume_tiers,
        overall_movement_tiers: template.overall_movement_tiers,
        specific_movement_tiers: template.specific_movement_tiers,
      }));

      const message = `Settings has been filled with "${template.name}" template`;
      AppToaster.show({ message: message, icon: 'tick', intent: Intent.PRIMARY, timeout: 3500 });
    }
  };

  return (
    <div className="">
      <h3 className="text-base font-bold my-1">Strategy Selection</h3>

      <FormGroup label="Strategy Type" labelFor="strategy_type" labelInfo="" helperText="">
        <HTMLSelect
          disabled={isRunning(item)}
          id="strategy_type"
          name="strategy_type"
          value={formState.strategy_type}
          onChange={handleInputChange}
        >
          {STRATEGY_TYPES.map(({ text, value }, index) => (
            <option value={value} key={index}>
              {text}
            </option>
          ))}
        </HTMLSelect>

        <Switch
          className="ml-10"
          large={false}
          inline={true}
          checked={formState.exit_disabled}
          name="exit_disabled"
          label="Disabled exit"
          // disabled={true}

          // Disabled without bluring it out on UI
          onChange={() => {}}
        />

        <Switch
          inline={true}
          checked={formState.instant_secondary_hedge}
          name="instant_secondary_hedge"
          label="Instant secondary hedge"
          // disabled={true}

          // Disabled without bluring it out on UI
          onChange={() => {}}
        />
      </FormGroup>

      <Tabs animate={true} id="wick-catcher-settings">
        <Tab
          id="markets"
          title="Pairs"
          className=""
          panel={
            <div className="">
              <h3 className="text-base font-bold my-1">Market Selection</h3>

              <PairsSelect
                // addNewItem={addNewItem}
                selectedItems={selectedPairs}
                editingItem={editingPair}
                selectEditingItem={setEditingPair}
                updateItem={updatePair}
                removeItemSide={removePairSide}
                removeItem={removePair}
                handleAddNext={handleAddNext}
              />
            </div>
          }
        />

        <Tab
          id="opportunity-settings"
          title="Opportunity Settings"
          panel={
            <>
              <div className="grid gap-6 grid-cols-2 p-1 mb-2">
                <h3 className="text-base font-bold">
                  <Tag intent={Intent.DANGER}>Short</Tag>
                </h3>

                <h3 className="text-base font-bold">
                  <Tag intent={Intent.SUCCESS}>Long</Tag>
                </h3>
              </div>

              <div className="grid gap-6 grid-cols-2 p-1 overflow-auto" style={{ maxHeight: '700px', minHeight: '420px' }}>
                <div>
                  {/* For existing Short settings, listing by min_target in desc order */}
                  {formState.opportunity_settings
                    .filter(({ id, side }) => id && side === 'short')
                    .sort((a, b) => b.min_target.localeCompare(a.min_target))
                    .map((setting, index) => {
                      return (
                        <OpportunitySettingForm
                          key={`opp-setting-${setting.id}-${index}`}
                          data={setting.data}
                          title={setting.key}
                          disabled={isRunning(item)}
                          onUpdateForm={handleOpportunitySettingChange}
                          onDelete={handleDeleteOpportunitySetting}
                        />
                      );
                    })}

                  {/* For new Short settings, listing in the order they are added */}
                  {formState.opportunity_settings
                    .filter(({ id, side }) => !id && side === 'short')
                    .map((setting, index) => {
                      return (
                        <OpportunitySettingForm
                          key={`opp-setting-${setting.id}-${index}`}
                          data={setting.data}
                          title={setting.key}
                          disabled={isRunning(item)}
                          onUpdateForm={handleOpportunitySettingChange}
                          onDelete={handleDeleteOpportunitySetting}
                        />
                      );
                    })}
                  <div
                    onClick={addNewOpportunitySetting('short')}
                    className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 text-center cursor-pointer"
                  >
                    Add new short opportunity settings
                  </div>
                </div>

                <div>
                  {/* For existing Long settings, listing by min_target in asc order */}
                  {formState.opportunity_settings
                    .filter(({ id, side }) => id && side === 'long')
                    .sort((a, b) => a.min_target.localeCompare(b.min_target))
                    .map((setting, index) => {
                      return (
                        <OpportunitySettingForm
                          key={`opp-setting-${setting.id}-${index}`}
                          data={setting.data}
                          title={setting.key}
                          disabled={isRunning(item)}
                          onUpdateForm={handleOpportunitySettingChange}
                          onDelete={handleDeleteOpportunitySetting}
                        />
                      );
                    })}

                  {/* For new Long settings, listing in the order they are added */}
                  {formState.opportunity_settings
                    .filter(({ id, side }) => !id && side === 'long')
                    .map((setting, index) => {
                      return (
                        <OpportunitySettingForm
                          key={`opp-setting-${setting.id}-${index}`}
                          data={setting.data}
                          title={setting.key}
                          disabled={isRunning(item)}
                          onUpdateForm={handleOpportunitySettingChange}
                          onDelete={handleDeleteOpportunitySetting}
                        />
                      );
                    })}

                  <div
                    onClick={addNewOpportunitySetting('long')}
                    className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 text-center cursor-pointer"
                  >
                    Add new long opportunity settings
                  </div>
                </div>
              </div>
            </>
          }
        />

        <Tab
          id="volume-movement-index-settings"
          title="Volume/Movement Index"
          panel={
            <div className="mt-5 h-full">
              <div className="mb-5 border-b pb-5 border-gray-300">
                <WickCatcherSettingsTemplateForm
                  item={item}
                  wickCatcherFormData={formState}
                  onSelectTemplate={handleSelectTemplate}
                ></WickCatcherSettingsTemplateForm>
              </div>

              <h4 className="text-base font-medium my-1">Volume/Movement Index tracking</h4>

              <div className="grid grid-cols-4 gap-5">
                <FormGroup
                  label="Tier debounce"
                  labelFor="market_tracking_debounce"
                  labelInfo=""
                  helperText="For determining which tier we're at"
                  disabled={isRunning(item)}
                >
                  <NumericInput
                    id="market_tracking_debounce"
                    name="debounce"
                    disabled={isRunning(item)}
                    fill={true}
                    value={formState.market_tracking_settings.debounce?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                    onValueChange={handleMarketTrackingSettingsNumberInputChange(true)}
                    buttonPosition="none"
                    placeholder="E.g. 500"
                  />
                </FormGroup>

                <FormGroup disabled={isRunning(item)} label="Overall time frame" labelFor="overall_time_frame" labelInfo="" helperText="">
                  <HTMLSelect
                    className="w-full"
                    disabled={isRunning(item)}
                    id="overall_time_frame"
                    name="overall_time_frame"
                    value={formState.market_tracking_settings.overall_time_frame}
                    onChange={handleMarketTrackingSettingsInputChange}
                  >
                    {TIMEFRAMES_IN_MINUTES.map(({ text, value }, index) => (
                      <option value={value} key={index}>
                        {text}
                      </option>
                    ))}
                  </HTMLSelect>
                </FormGroup>

                <FormGroup disabled={isRunning(item)} label="Current time frame" labelFor="current_time_frame" labelInfo="" helperText="">
                  <HTMLSelect
                    className="w-full"
                    disabled={isRunning(item)}
                    id="current_time_frame"
                    name="current_time_frame"
                    value={formState.market_tracking_settings.current_time_frame}
                    onChange={handleMarketTrackingSettingsInputChange}
                  >
                    {TIMEFRAMES_IN_MINUTES.map(({ text, value }, index) => (
                      <option value={value} key={index}>
                        {text}
                      </option>
                    ))}
                  </HTMLSelect>
                </FormGroup>

                <FormGroup disabled={isRunning(item)} label="Movement time frame" labelFor="movement_time_frame">
                  <HTMLSelect
                    className="w-full"
                    disabled={isRunning(item)}
                    id="movement_time_frame"
                    name="movement_time_frame"
                    value={formState.market_tracking_settings.movement_time_frame}
                    onChange={handleMarketTrackingSettingsInputChange}
                  >
                    {TIMEFRAMES_IN_MINUTES.map(({ text, value }, index) => (
                      <option value={value} key={index}>
                        {text}
                      </option>
                    ))}
                  </HTMLSelect>
                </FormGroup>
              </div>

              <h4 className="text-base font-medium my-1">Multi-tiered system</h4>

              <div className="grid gap-5 grid-cols-4 mb-5 border-b pb-5 border-gray-300">
                <div>
                  <h5 className="text-sm font-medium my-3">Volume (Overall)</h5>

                  <div>
                    {formState.overall_volume_tiers.map((tier, index) => {
                      const isTierZero = index === 0 && tier.trigger_threshold === 0;

                      return (
                        <div key={tier.tier} className="border border-gray-200 rounded-md py-2 px-3 mb-4 group">
                          <h6 className="text-sm font-light my-1 relative">
                            <Tag minimal={false} intent={Intent.NONE}>
                              Tier {index}
                            </Tag>

                            {!isTierZero && (
                              <span
                                onClick={() => handleDeleteTierSetting('overall_volume_tiers', index)}
                                className="absolute top-0 right-0 p-0 text-gray-400 hover:text-gray-500 hidden group-hover:block cursor-pointer"
                              >
                                <Icon icon="cross"></Icon>
                              </span>
                            )}
                          </h6>

                          <div className="grid grid-cols-3 gap-3">
                            <FormGroup label={<span className="text-xs">Trigger threshold</span>} labelFor={`${index}_trigger_threshold`}>
                              <NumericInput
                                id={`${index}_trigger_threshold`}
                                name="trigger_threshold"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.trigger_threshold?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('overall_volume_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 5"
                              />
                            </FormGroup>

                            <FormGroup label={<span className="text-xs">Short multiplier</span>} labelFor={`${index}_short_multiplier`}>
                              <NumericInput
                                id={`${index}_short_multiplier`}
                                name="short_multiplier"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.short_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('overall_volume_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 2"
                              />
                            </FormGroup>

                            <FormGroup label={<span className="text-xs">Long multiplier</span>} labelFor={`${index}_long_multiplier`}>
                              <NumericInput
                                id={`${index}_long_multiplier`}
                                name="long_multiplier"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.long_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('overall_volume_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 2"
                              />
                            </FormGroup>
                          </div>
                        </div>
                      );
                    })}

                    {/* Big button with a placeholder hidden behind */}
                    <div className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 text-center cursor-pointer relative">
                      <div className="invisible">
                        <h6 className="text-sm font-medium my-1 relative">Tier</h6>

                        <div className="grid grid-cols-3 gap-3">
                          <FormGroup label="Trigger threshold" labelInfo="" helperText="">
                            <NumericInput name="trigger_threshold" fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 5" />
                          </FormGroup>

                          <FormGroup label="Short multiplier" labelInfo="" helperText="">
                            <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                          </FormGroup>

                          <FormGroup label="Long multiplier" labelInfo="" helperText="">
                            <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                          </FormGroup>
                        </div>
                      </div>

                      <div
                        onClick={() => handleAddNewTierSetting('overall_volume_tiers')}
                        className="absolute w-full h-full top-0 left-0 flex justify-center items-center"
                      >
                        <span className="block">Add new tier</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="text-sm font-medium my-3">Volume (Market-specific)</h5>

                  <div>
                    {formState.specific_volume_tiers.map((tier, index) => {
                      const isTierZero = index === 0 && tier.trigger_threshold === 0;

                      return (
                        <div key={tier.tier} className="border border-gray-200 rounded-md py-2 px-3 mb-4 group">
                          <h6 className="text-sm font-light my-1 relative">
                            <Tag minimal={false} intent={Intent.NONE}>
                              Tier {index}
                            </Tag>

                            {!isTierZero && (
                              <span
                                onClick={() => handleDeleteTierSetting('specific_volume_tiers', index)}
                                className="absolute top-0 right-0 p-0 text-gray-400 hover:text-gray-500 hidden group-hover:block cursor-pointer"
                              >
                                <Icon icon="cross"></Icon>
                              </span>
                            )}
                          </h6>

                          <div className="grid grid-cols-3 gap-3">
                            <FormGroup label={<span className="text-xs">Trigger threshold</span>} labelFor={`${index}_trigger_threshold`}>
                              <NumericInput
                                id={`${index}_trigger_threshold`}
                                name="trigger_threshold"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.trigger_threshold?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('specific_volume_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 5"
                              />
                            </FormGroup>

                            <FormGroup label={<span className="text-xs">Short multiplier</span>} labelFor={`${index}_short_multiplier`}>
                              <NumericInput
                                id={`${index}_short_multiplier`}
                                name="short_multiplier"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.short_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('specific_volume_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 2"
                              />
                            </FormGroup>

                            <FormGroup label={<span className="text-xs">Long multiplier</span>} labelFor={`${index}_long_multiplier`}>
                              <NumericInput
                                id={`${index}_long_multiplier`}
                                name="long_multiplier"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.long_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('specific_volume_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 2"
                              />
                            </FormGroup>
                          </div>
                        </div>
                      );
                    })}

                    {/* Big button with a placeholder hidden behind */}
                    <div className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 text-center cursor-pointer relative">
                      <div className="invisible">
                        <h6 className="text-sm font-medium my-1 relative">Tier</h6>

                        <div className="grid grid-cols-3 gap-3">
                          <FormGroup label="Trigger threshold" labelInfo="" helperText="">
                            <NumericInput name="trigger_threshold" fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 5" />
                          </FormGroup>

                          <FormGroup label="Short multiplier" labelInfo="" helperText="">
                            <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                          </FormGroup>

                          <FormGroup label="Long multiplier" labelInfo="" helperText="">
                            <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                          </FormGroup>
                        </div>
                      </div>

                      <div
                        onClick={() => handleAddNewTierSetting('specific_volume_tiers')}
                        className="absolute w-full h-full top-0 left-0 flex justify-center items-center"
                      >
                        <span className="block">Add new tier</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="text-sm font-medium my-3">Movevemt (Overall)</h5>

                  <div>
                    {formState.overall_movement_tiers.map((tier, index) => {
                      const isTierZero = index === 0 && tier.trigger_threshold === 0;

                      return (
                        <div key={tier.tier} className="border border-gray-200 rounded-md py-2 px-3 mb-4 group">
                          <h6 className="text-sm font-light my-1 relative">
                            <Tag minimal={false} intent={Intent.NONE}>
                              Tier {index}
                            </Tag>

                            {!isTierZero && (
                              <span
                                onClick={() => handleDeleteTierSetting('overall_movement_tiers', index)}
                                className="absolute top-0 right-0 p-0 text-gray-400 hover:text-gray-500 hidden group-hover:block cursor-pointer"
                              >
                                <Icon icon="cross"></Icon>
                              </span>
                            )}
                          </h6>

                          <div className="grid grid-cols-3 gap-3">
                            <FormGroup label={<span className="text-xs">Trigger threshold</span>} labelFor={`${index}_trigger_threshold`}>
                              <NumericInput
                                id={`${index}_trigger_threshold`}
                                name="trigger_threshold"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.trigger_threshold?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('overall_movement_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 5"
                              />
                            </FormGroup>

                            <FormGroup label={<span className="text-xs">Short multiplier</span>} labelFor={`${index}_short_multiplier`}>
                              <NumericInput
                                id={`${index}_short_multiplier`}
                                name="short_multiplier"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.short_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('overall_movement_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 2"
                              />
                            </FormGroup>

                            <FormGroup label={<span className="text-xs">Long multiplier</span>} labelFor={`${index}_long_multiplier`}>
                              <NumericInput
                                id={`${index}_long_multiplier`}
                                name="long_multiplier"
                                fill={true}
                                disabled={isTierZero}
                                value={tier.long_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                onValueChange={handleTierSettingNumberValueChange('overall_movement_tiers', index)}
                                buttonPosition="none"
                                placeholder="E.g. 2"
                              />
                            </FormGroup>
                          </div>
                        </div>
                      );
                    })}

                    {/* Big button with a placeholder hidden behind */}
                    <div className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 text-center cursor-pointer relative">
                      <div className="invisible">
                        <h6 className="text-sm font-medium my-1 relative">Tier</h6>

                        <div className="grid grid-cols-3 gap-3">
                          <FormGroup label="Trigger threshold" labelInfo="" helperText="">
                            <NumericInput name="trigger_threshold" fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 5" />
                          </FormGroup>

                          <FormGroup label="Short multiplier" labelInfo="" helperText="">
                            <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                          </FormGroup>

                          <FormGroup label="Long multiplier" labelInfo="" helperText="">
                            <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                          </FormGroup>
                        </div>
                      </div>

                      <div
                        onClick={() => handleAddNewTierSetting('overall_movement_tiers')}
                        className="absolute w-full h-full top-0 left-0 flex justify-center items-center"
                      >
                        <span className="block">Add new tier</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="text-sm font-medium my-3">Movement (Market-specific)</h5>

                  <div>
                    <div>
                      {formState.specific_movement_tiers.map((tier, index) => {
                        const isTierZero = index === 0 && tier.trigger_threshold === 0;

                        return (
                          <div key={tier.tier} className="border border-gray-200 rounded-md py-2 px-3 mb-4 group">
                            <h6 className="text-sm font-light my-1 relative">
                              <Tag minimal={false} intent={Intent.NONE}>
                                Tier {index}
                              </Tag>

                              {!isTierZero && (
                                <span
                                  onClick={() => handleDeleteTierSetting('specific_movement_tiers', index)}
                                  className="absolute top-0 right-0 p-0 text-gray-400 hover:text-gray-500 hidden group-hover:block cursor-pointer"
                                >
                                  <Icon icon="cross"></Icon>
                                </span>
                              )}
                            </h6>

                            <div className="grid grid-cols-3 gap-3">
                              <FormGroup label={<span className="text-xs">Trigger threshold</span>} labelFor={`${index}_trigger_threshold`}>
                                <NumericInput
                                  id={`${index}_trigger_threshold`}
                                  name="trigger_threshold"
                                  fill={true}
                                  disabled={isTierZero}
                                  value={tier.trigger_threshold?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                  onValueChange={handleTierSettingNumberValueChange('specific_movement_tiers', index)}
                                  buttonPosition="none"
                                  placeholder="E.g. 5"
                                />
                              </FormGroup>

                              <FormGroup label={<span className="text-xs">Short multiplier</span>} labelFor={`${index}_short_multiplier`}>
                                <NumericInput
                                  id={`${index}_short_multiplier`}
                                  name="short_multiplier"
                                  fill={true}
                                  disabled={isTierZero}
                                  value={tier.short_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                  onValueChange={handleTierSettingNumberValueChange('specific_movement_tiers', index)}
                                  buttonPosition="none"
                                  placeholder="E.g. 2"
                                />
                              </FormGroup>

                              <FormGroup label={<span className="text-xs">Long multiplier</span>} labelFor={`${index}_long_multiplier`}>
                                <NumericInput
                                  id={`${index}_long_multiplier`}
                                  name="long_multiplier"
                                  fill={true}
                                  disabled={isTierZero}
                                  value={tier.long_multiplier?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
                                  onValueChange={handleTierSettingNumberValueChange('specific_movement_tiers', index)}
                                  buttonPosition="none"
                                  placeholder="E.g. 2"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {/* Big button with a placeholder hidden behind */}
                    <div className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 text-center cursor-pointer relative">
                      <div className="invisible">
                        <h6 className="text-sm font-medium my-1 relative">Tier</h6>

                        <div className="grid grid-cols-3 gap-3">
                          <FormGroup label="Trigger threshold" labelInfo="" helperText="">
                            <NumericInput name="trigger_threshold" fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 5" />
                          </FormGroup>

                          <FormGroup label="Short multiplier" labelInfo="" helperText="">
                            <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                          </FormGroup>

                          <FormGroup label="Long multiplier" labelInfo="" helperText="">
                            <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                          </FormGroup>
                        </div>
                      </div>

                      <div
                        onClick={() => handleAddNewTierSetting('specific_movement_tiers')}
                        className="absolute w-full h-full top-0 left-0 flex justify-center items-center"
                      >
                        <span className="block">Add new tier</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <Tab
          id="primary-exit-settings"
          title="Exit Settings"
          disabled={formState?.strategy_type === WickCatcherStrategyType.InstantArb}
          panel={
            <div className="overflow-auto p-1" style={{ maxHeight: '700px' }}>
              <PrimaryExitSettingsForm
                formState={formState.exit_settings}
                disabled={isRunning(item)}
                onInputChange={handleExitSettingsInputChange}
                onNumberInputChange={handleExitSettingsNumberInputChange}
                onSwitchChange={handleExitSettingsSwitchChange}
              />
            </div>
          }
        />

        <Tab
          id="high-low-pause-settings"
          title="High/Low Pause"
          panel={
            <div className="overflow-auto p-1" style={{ maxHeight: '700px' }}>
              <HighLowPauseSettingsForm
                formState={formState.high_low_pause_settings}
                disabled={isRunning(item)}
                onInputChange={handleHighLowPauseSettingsInputChange}
                onNumberInputChange={handleHighLowPauseSettingsNumberInputChange}
                onSwitchChange={handleHighLowPauseSettingsSwitchChange}
              />
            </div>
          }
        />
      </Tabs>

      <div className="mt-7 text-center">
        {formState.id && (
          <Button
            intent="primary"
            large={true}
            disabled={validationError.size > 0 || isRunning(item)}
            loading={isProcessing}
            onClick={handleUpdate}
          >
            Update
          </Button>
        )}

        {!formState.id && (
          <Button
            intent="primary"
            large={true}
            disabled={validationError.size > 0 || isRunning(item)}
            loading={isProcessing}
            onClick={handleCreate}
          >
            Create
          </Button>
        )}
      </div>
    </div>
  );
}
