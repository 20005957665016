import { Button, ControlGroup, FormGroup, InputGroup, Intent, Pre } from '@blueprintjs/core';
import { ChangeEvent, FormEvent, useCallback, useState } from 'react';

import axios from '../../../shared/custom-axios';
import { AppToaster } from '../../../shared/app-toaster';
import { formatNextExecutionDateTime } from './form';

interface Props {
  default_items: string[];
  cron_expression: string;
  timezone: string;
}

export function ProjectedUpcomingRuns({ default_items, cron_expression, timezone }: Props) {
  const [items, setItems] = useState(default_items);
  const [nextRunsNumber, setNextRunsNumber] = useState(5);

  const handleGetProjectedUpcomingRuns = useCallback((next_runs_number) => {
    let params = {
      cron_expression: cron_expression,
      next_runs_number: next_runs_number,
      timezone: timezone,
    };

    axios
      .post('/api/scheduled_tasks/projected_upcoming_runs', params)
      .then(({ data }) => {
        const { result } = data;
        setItems(result);
      })
      .catch((error) => {
        setItems([]);
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 15000 });
      });
  }, []);

  const handleNextRunsNumber = (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;

    setNextRunsNumber(Number(value));
  };

  return (
    <div>
      <FormGroup label="Number of next projected runs you want to see" labelFor="occurrence" labelInfo="(required)">
        <ControlGroup fill={false} vertical={false}>
          <InputGroup
            id="nextRunsNumber"
            name="nextRunsNumber"
            fill={true}
            value={String(nextRunsNumber)}
            onChange={handleNextRunsNumber}
            onBlur={() => handleGetProjectedUpcomingRuns(nextRunsNumber)}
          />

          <Button icon="arrow-down" onClick={() => handleGetProjectedUpcomingRuns(nextRunsNumber)}></Button>
        </ControlGroup>
      </FormGroup>

      <Pre className="whitespace-pre-wrap overflow-auto h-96">
        <div className="grid grid-cols-3 hover:bg-gray-300 mb-1">
          <span>No.</span>
          <span>Date</span>
          <span>Time</span>
        </div>

        {items.map((dateTime, index) => {
          const [date, daysInWeek, time] = formatNextExecutionDateTime(dateTime, timezone).split(' ');

          return (
            <div className="grid grid-cols-3 hover:bg-gray-200">
              <span>{index + 1}. </span>
              <span>
                {date} {daysInWeek}
              </span>
              <span>{time}</span>
            </div>
          );
        })}
      </Pre>
    </div>
  );
}
