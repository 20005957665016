import {
  Button,
  Classes,
  ControlGroup,
  Divider,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Position,
  Switch,
  Tag,
} from '@blueprintjs/core';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { DateRange, DateRangePicker, TimePrecision } from '@blueprintjs/datetime';
import { unitOfTime } from 'moment';
import moment from 'moment-timezone';
import { StrategyType } from '../../../../shared/interfaces/bot';
import { MomentDateRange } from '../../../common/moment-date/moment-date';
import { AppToaster } from '../../../../shared/app-toaster';
import { constructSameDateTimeWithDifferentTimeZone } from '../../../../shared/utils';
import { BaliDateTimeFormater, TimezoneContext } from '../../../../contexts/timezone';

const TIME_FORMAT = 'HH:mm:ss.SSS';
const DATETIME_FORMAT = `YYYY-MM-DDT${TIME_FORMAT}`;

interface Props {
  filters: SearchFilter[];
  setFilters: React.Dispatch<React.SetStateAction<SearchFilter[]>>;
  onSearch: (filters: SearchFilter[], force?: boolean) => void;
  onFetchAggregation: (filters: SearchFilter[]) => void;
}

export interface SearchFilter {
  field: string;
  value: string;
  op: string;
}

interface IAbsoluteRange {
  value: string;
  period: unitOfTime.Base;
}

interface IDateOption {
  label: string;
  value?: Date;
}

interface IDateRangePickerExampleState {
  allowSingleDayRange?: boolean;
  singleMonthOnly?: boolean;
  contiguousCalendarMonths?: boolean;
  dateRange?: DateRange;
  maxDateIndex?: number;
  minDateIndex?: number;
  reverseMonthAndYearMenus?: boolean;
  shortcuts?: boolean;
  timePrecision?: TimePrecision;
}

export function TradeFeedSearchTool({ filters, setFilters, onSearch, onFetchAggregation }: Props) {
  const { dateTimeFormater } = useContext(TimezoneContext);
  const initFormData = { field: '', op: '', value: '' };
  const [formState, setFormState] = useState<SearchFilter>(initFormData);
  const [formError, setFormError] = useState<Map<string, string>>(new Map());
  const [isOpenNewSearch, setIsOpenNewSearch] = useState<boolean>(false);
  const [useRelativeRange, setUseRelativeRange] = useState<boolean>(false);
  const [relativeRange, setRelativeRange] = useState<IAbsoluteRange>({ value: '10', period: 'seconds' });
  const [selectedDateRangeUTC, setSelectedDateRangeUTC] = useState<DateRange>([null, null]);

  useEffect(() => {
    const dateFilter = filters.find((f: SearchFilter) => f.field === 'transaction_time');
    if (dateFilter) {
      const [startTimeUTC, endTimeUTC] = dateFilter.value.split('>');

      const startTimeUserTz = moment.tz(startTimeUTC, BaliDateTimeFormater.resolvedOptions().timeZone);
      const endTimeUserTz = moment.tz(endTimeUTC, BaliDateTimeFormater.resolvedOptions().timeZone);

      // Datetime picker and other places receive Date objects to show local datetimes with browser's timezone
      //
      // Here we construct Date objects (with datetime representation in user's prefered timezone which could be different from browser's timezone)
      // to show and use in any other component as appearance
      const startTime = new Date(startTimeUserTz.format(DATETIME_FORMAT));
      const endTime = new Date(endTimeUserTz.format(DATETIME_FORMAT));

      setSelectedDateRangeUTC([startTime, endTime]);
    }
    setFilters(filters);
  }, [filters]);

  const [selectedDayRange, setSelectedDayRange] = useState<IDateRangePickerExampleState>({
    allowSingleDayRange: false,
    contiguousCalendarMonths: true,
    dateRange: [null, null],
    maxDateIndex: 0,
    minDateIndex: 0,
    reverseMonthAndYearMenus: false,
    shortcuts: true,
    singleMonthOnly: false,
    timePrecision: TimePrecision.SECOND,
  });

  const supportedFields = [
    { name: 'percentage', text: 'Target' },
    { name: 'ratio', text: 'Ratio' },
    { name: 'bot', text: 'Bot' },
    { name: 'strategy_type', text: 'Strategy Type' },
    { name: 'name', text: 'Pair Name' },
    { name: 'hedge_state', text: 'Hedge State' },
    { name: 'direction', text: 'Direction' },
    { name: 'quantity', text: 'Quantity' },
    { name: 'transaction_time', text: 'Time' },
    { name: 'lag_time', text: 'Lag' },
    { name: 'external_id', text: 'External ID' },
    { name: 'internal_id', text: 'Internal ID' },
    { name: 'side', text: 'Order Type' },
    { name: 'last_fill_volume', text: 'Fill Volume / Total Volume' },
    { name: 'last_fill_price', text: 'Fill Price' },
    { name: 'avg_fill_price', text: 'Avg Fill Price' },
    { name: 'achieved', text: 'Achieved' },
    { name: 'replenish', text: 'Replenish' },
    { name: 'opportunity_id', text: 'Opportunity ID' },
  ];

  const supportedOperators = [
    { name: 'eq', text: 'is' },
    { name: 'not', text: 'is not' },
    { name: 'in', text: 'in' },
  ];

  const supportedDayRanges = [
    { name: 'seconds', text: 'Seconds ago' },
    { name: 'minutes', text: 'Minutes ago' },
    { name: 'hours', text: 'Hours ago' },
    { name: 'days', text: 'Days ago' },
  ];

  const MIN_DATE_OPTIONS: IDateOption[] = [
    { label: 'None', value: undefined },
    {
      label: '4 months ago',
      value: moment().add(-4, 'months').toDate(),
    },
    {
      label: '1 year ago',
      value: moment().add(-1, 'years').toDate(),
    },
  ];

  const MAX_DATE_OPTIONS: IDateOption[] = [
    { label: 'None', value: undefined },
    {
      label: '4 months from now',
      value: moment().add(4, 'months').toDate(),
    },
    {
      label: '1 year from now',
      value: moment().add(1, 'years').toDate(),
    },
  ];

  const handleKeyPress = (evt: any) => {
    if (evt && evt.key === 'Enter') {
      document.querySelectorAll('#ApplySearchBtn').forEach((el: any) => el.click());
    }
  };

  const handleDateChange = useCallback((dateRange: DateRange) => {
    setSelectedDateRangeUTC(dateRange);
  }, []);

  const handleSearchChange = useCallback((event: any) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  useEffect(() => {
    if (!useRelativeRange) return;

    const endDate = new Date();

    const startDate = moment(endDate)
      .add(Number(relativeRange.value) * -1, relativeRange.period)
      .toDate();

    setSelectedDateRangeUTC([startDate, endDate]);
  }, [useRelativeRange, relativeRange]);

  const handleRelativeRangeChange = (event: any) => {
    const { name, value } = event.currentTarget;
    const newAbsoluteRange = {
      ...relativeRange,
      [name]: value,
    };

    setRelativeRange(newAbsoluteRange);
  };

  const handleApplySearch = (evt: any) => {
    const error = new Map<string, string>();
    if (!formState.value || formState.value == '') {
      error.set('value', 'Missing value');
    }

    if (!formState.op || formState.op == '') {
      error.set('operator', 'Please select operator');
    }

    if (!formState.field || formState.field == '') {
      error.set('field', 'Please select field');
    }

    setFormError(error);

    if (error.size == 0) {
      setIsOpenNewSearch(false);
      let newFilters;
      const index = filters.findIndex((f: SearchFilter) => f.field == formState.field);
      if (index >= 0) {
        filters.splice(index, 1, formState);
        newFilters = [...filters];
      } else {
        newFilters = [...filters, formState];
      }
      setFilters(newFilters);
      onSearch(newFilters);
    } else {
      evt.preventDefault();
      evt.stopPropagation();
    }
  };

  const handleDateRangeSearch = useCallback(
    (event: any) => {
      const error = new Map<string, string>();
      if (useRelativeRange && (!relativeRange.value || relativeRange.value == '')) {
        error.set('range_value', 'Please fill a number');
      }
      if (useRelativeRange && !relativeRange.period) {
        error.set('range_period', 'Please select period');
      }

      let [startTime, endTime] = selectedDateRangeUTC;

      if (selectedDateRangeUTC.length < 2 || !selectedDateRangeUTC[0] || !selectedDateRangeUTC[1]) {
        error.set('selected_range', 'Please select a range');
      }

      setFormError(error);

      if (error.size === 0) {
        setIsOpenNewSearch(false);
        let newFilters;
        const index = filters.findIndex((f: SearchFilter) => f.field === 'transaction_time');
        const startDate = startTime ? constructSameDateTimeWithDifferentTimeZone(startTime, dateTimeFormater) : null;
        const startDateUTCString = startDate && startDate.utc().format(`${DATETIME_FORMAT}[Z]`);

        const endDate =
          selectedDateRangeUTC.length > 1 && endTime ? constructSameDateTimeWithDifferentTimeZone(endTime, dateTimeFormater) : null;
        const endDateUTCString = endDate && endDate.utc().format(`${DATETIME_FORMAT}[Z]`);

        const dateFilter = { field: 'transaction_time', op: 'in', value: `${startDateUTCString}>${endDateUTCString}` };

        if (index >= 0) {
          filters.splice(index, 1, dateFilter);
          newFilters = [...filters];
        } else {
          newFilters = [...filters, dateFilter];
        }
        setFilters(newFilters);
        onSearch(newFilters);
      } else {
        event.preventDefault();
        event.stopPropagation();

        AppToaster.show({ message: JSON.stringify(Object.fromEntries(error)), icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      }
    },
    [useRelativeRange, relativeRange, selectedDateRangeUTC, filters, dateTimeFormater, setFilters, onSearch],
  );

  const resetError = () => {
    setFormError(new Map());
  };

  const handleOpenNewSearch = () => {
    resetError();
    setFormState(initFormData);
    setIsOpenNewSearch(true);
  };

  const handleCloseNewSearch = () => {
    resetError();
    setFormState(initFormData);
    setIsOpenNewSearch(false);
  };

  const handleEditSearch = (searchFilter: SearchFilter) => {
    resetError();
    setFormState(searchFilter);
    setIsOpenNewSearch(false);
  };

  const searchBox = (filter?: SearchFilter) => {
    return (
      <div className="p-5">
        <div className="flex flex-row">
          <FormGroup
            label="Field"
            labelFor="field"
            intent={formError.has('field') ? Intent.DANGER : Intent.NONE}
            helperText={formError.has('field') ? formError.get('field') : null}
          >
            <HTMLSelect
              id="field"
              name="field"
              className={formError.has('field') ? 'border rounded-sm border-red-500' : ''}
              value={formState.field}
              onChange={handleSearchChange}
            >
              <option value="">Choose an field...</option>
              {supportedFields.map(({ name, text }) => (
                <option value={name} key={name}>
                  {text}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>
          <FormGroup
            className="pl-5"
            label="Operator"
            labelFor="operator"
            intent={formError.has('operator') ? Intent.DANGER : Intent.NONE}
            helperText={formError.has('operator') ? formError.get('operator') : null}
          >
            <HTMLSelect
              id="operator"
              name="op"
              className={formError.has('operator') ? 'border rounded-sm border-red-400' : ''}
              value={formState.op}
              onChange={handleSearchChange}
            >
              <option value="">Choose operator...</option>
              {supportedOperators.map(({ name, text }) => (
                <option value={name} key={name}>
                  {text}
                </option>
              ))}
            </HTMLSelect>
          </FormGroup>
        </div>
        <FormGroup
          label="Value"
          labelFor="value"
          intent={formError.has('value') ? Intent.DANGER : Intent.NONE}
          helperText={formError.has('value') ? formError.get('value') : null}
        >
          <InputGroup
            id="text-input"
            name="value"
            value={formState.value}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            intent={formError.has('value') ? Intent.DANGER : Intent.NONE}
            placeholder="Value to search, ex: 10, 99:999"
          />
        </FormGroup>
        <div className={'flex items-center justify-center'}>
          <Button
            intent={Intent.PRIMARY}
            id="ApplySearchBtn"
            className={`${Classes.POPOVER_DISMISS} font-semibold`}
            onClick={handleApplySearch}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  const timeRangeFilter = () => {
    const { minDateIndex, maxDateIndex, dateRange, ...restFields } = selectedDayRange;
    const minDate = MIN_DATE_OPTIONS[minDateIndex || 0].value;
    const maxDate = MAX_DATE_OPTIONS[maxDateIndex || 0].value;
    return (
      <div>
        <DateRangePicker
          {...restFields}
          className={`${Classes.ELEVATION_1} custom-date-picker`}
          maxDate={maxDate}
          minDate={minDate}
          value={selectedDateRangeUTC}
          onChange={handleDateChange}
          allowSingleDayRange={true}
          footerElement={footerElement}
          contiguousCalendarMonths={false}
          timePrecision={TimePrecision.MILLISECOND}
        />

        <div className={'flex items-center justify-center pt-2 pb-2'}>
          <Button
            intent={Intent.PRIMARY}
            id="ApplySearchBtn"
            className={`${Classes.POPOVER_DISMISS} font-semibold`}
            onClick={handleDateRangeSearch}
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  const footerElement = (
    <div className={'w-full'}>
      <Divider></Divider>

      <div className="flex flex-row items-center justify-between mt-2">
        <Switch
          className={'pt-2 mr-3 ml-2'}
          checked={useRelativeRange}
          onChange={() => setUseRelativeRange(!useRelativeRange)}
          labelElement={<strong>Use relative</strong>}
        />
        <InputGroup
          id="text-input"
          name="value"
          className="mr-5"
          value={relativeRange.value}
          onChange={handleRelativeRangeChange}
          onKeyPress={handleKeyPress}
          disabled={!useRelativeRange}
          intent={formError.has('range_value') ? Intent.DANGER : Intent.NONE}
          placeholder="Number of seconds, hours or days"
        />
        <HTMLSelect
          id="period"
          name="period"
          className={formError.has('range_period') ? 'border rounded-sm border-red-400 mr-3' : 'mr-3'}
          disabled={!useRelativeRange}
          value={relativeRange.period}
          onChange={handleRelativeRangeChange}
        >
          {supportedDayRanges.map(({ name, text }) => (
            <option value={name} key={name}>
              {text}
            </option>
          ))}
        </HTMLSelect>
      </div>

      <Divider></Divider>

      <div className="mx-2 mt-5 mb-5">
        <p className="font-bold">New Selecting Range</p>

        <MomentDateRange withTime={true} range={selectedDateRangeUTC} />
      </div>
    </div>
  );

  const buildTagName = (filter: SearchFilter) => {
    switch (filter.op) {
      case 'eq':
        return `${filter.field} = ${filter.value}`;
      case 'not':
        return `${filter.field} not ${filter.value}`;
      case 'in':
        return `${filter.field} between (${filter.value})`;
    }
  };

  const buildDateRangeText = () => {
    const dateFilter = filters.find((f: SearchFilter) => f.field === 'transaction_time');

    // We parse again from URL (instead of using selectedDateRangeUTC) to avoid its text representation keeps changing as user
    // changes date range in the DateTime picker component
    if (dateFilter) {
      const [startTimeUTC, endTimeUTC] = dateFilter.value.split('>');

      const startTimeUserTz = moment.tz(startTimeUTC, BaliDateTimeFormater.resolvedOptions().timeZone);
      const endTimeUserTz = moment.tz(endTimeUTC, BaliDateTimeFormater.resolvedOptions().timeZone);

      return `${startTimeUserTz.format(`LL ${TIME_FORMAT}`)} ➜ ${endTimeUserTz.format(`LL ${TIME_FORMAT}`)}`;
    }

    return 'Add date filter';
  };

  const handleQuickFilters = useCallback((field: string, value: StrategyType) => {
    setFilters((prevState) => {
      const index = prevState.findIndex((f: SearchFilter) => f.field === field);

      if (index >= 0) {
        filters.splice(index, 1);
      }

      const updatedFilters = [...filters, { field: field, value: value, op: 'eq' }];

      onSearch(updatedFilters);

      return updatedFilters;
    });
  }, []);

  return (
    <>
      <div className="flex flex-row items-center">
        <Popover2
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          enforceFocus={false}
          content={searchBox()}
          minimal={false}
          isOpen={isOpenNewSearch}
          onInteraction={(state) => {
            if (!state) {
              handleCloseNewSearch();
            }
          }}
          position={Position.BOTTOM}
        >
          <Button
            intent={Intent.PRIMARY}
            className="font-semibold whitespace-nowrap mr-2"
            outlined={true}
            icon={'add'}
            onClick={() => {
              if (isOpenNewSearch) {
                handleCloseNewSearch();
              } else {
                handleOpenNewSearch();
              }
            }}
          >
            Add filter
          </Button>
        </Popover2>

        <Popover2
          popoverClassName={Classes.POPOVER_CONTENT_SIZING}
          enforceFocus={false}
          content={timeRangeFilter()}
          minimal={false}
          position={Position.BOTTOM}
        >
          <Tag
            className={'mr-2 text-black font-semibold'}
            intent={Intent.PRIMARY}
            interactive={true}
            onRemove={() => {
              const updatedFilters = filters.filter((f: SearchFilter) => f.field !== 'transaction_time');
              setFilters(updatedFilters);
              setSelectedDateRangeUTC([null, null]);
              onSearch(updatedFilters);
            }}
            minimal={true}
            large={true}
          >
            {buildDateRangeText()}
          </Tag>
        </Popover2>

        <Button intent={Intent.PRIMARY} className="font-semibold" onClick={() => onSearch(filters, true)}>
          Search
        </Button>

        <ControlGroup className="ml-5">
          {filters.map((filter: SearchFilter) => {
            if (filter.field === 'transaction_time') {
              return null;
            }
            return (
              <div className={'filter-item'} key={`filter-item-${filter.field}`}>
                <Popover2
                  popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                  enforceFocus={false}
                  content={searchBox(filter)}
                  minimal={false}
                  position={Position.BOTTOM}
                  onOpening={() => handleEditSearch(filter)}
                >
                  <Tag
                    className={'mr-2 text-black font-semibold'}
                    interactive={true}
                    onRemove={() => {
                      const updatedFilters = filters.filter((f: SearchFilter) => f.field !== filter.field);
                      setFilters(updatedFilters);
                      onSearch(updatedFilters);
                    }}
                    minimal={true}
                    large={true}
                  >
                    {buildTagName(filter)}
                  </Tag>
                </Popover2>
              </div>
            );
          })}
        </ControlGroup>
      </div>

      <div className="mt-3">
        <div className="float-left">
          <span className="mr-3">Quick Filters:</span>

          <span className="mr-3">
            <Tag
              interactive={true}
              minimal={true}
              large={true}
              onClick={() => handleQuickFilters('strategy_type', StrategyType.V1Strategy)}
            >
              V1 Arbitrage
            </Tag>
          </span>

          <span className="mr-3">
            <Tag
              interactive={true}
              minimal={true}
              large={true}
              onClick={() => handleQuickFilters('strategy_type', StrategyType.WickCatcher_Standard)}
            >
              Wick Catcher (Standard)
            </Tag>
          </span>

          <span className="mr-3">
            <Tag
              interactive={true}
              minimal={true}
              large={true}
              onClick={() => handleQuickFilters('strategy_type', StrategyType.WickCatcher_InstantArb)}
            >
              Wick Catcher (Instant Arb)
            </Tag>
          </span>

          <span className="mr-3">
            <Tag
              interactive={true}
              minimal={true}
              large={true}
              onClick={() => handleQuickFilters('strategy_type', StrategyType.PairsTrading)}
            >
              Pairs Trading
            </Tag>
          </span>

          <span className="mr-3">
            <Tag
              interactive={true}
              minimal={true}
              large={true}
              onClick={() => handleQuickFilters('strategy_type', StrategyType.NFTMarketMaking)}
            >
              Blur.io Trading
            </Tag>
          </span>
        </div>
      </div>

      <div className="float-right">
        <Button intent={Intent.PRIMARY} className="font-semibold" onClick={() => onFetchAggregation(filters)}>
          Show Aggregated Results
        </Button>
      </div>
    </>
  );
}
