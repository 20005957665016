import { Route, Routes } from 'react-router-dom';

import { LeftSidebarWithoutNavLayout } from '../layout/left-sidebar-without-nav';
import { ScheduleNewPage } from '../../pages/schedules/new';
import { ScheduleEditPage } from '../../pages/schedules/edit';
import { SchedulesIndexPage } from '../../pages/schedules';

export function SchedulesRoutes() {
  return (
    <LeftSidebarWithoutNavLayout>
      <Routes>
        <Route path="/new" element={<ScheduleNewPage />} />
        <Route path=":id/edit" element={<ScheduleEditPage />} />
        <Route path="/" element={<SchedulesIndexPage />} />
      </Routes>
    </LeftSidebarWithoutNavLayout>
  );
}
