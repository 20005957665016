import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import axios from '../../../../shared/custom-axios';
import { Button, ButtonGroup, Card, H5, H6, Icon, Intent, Position, Tab, Tabs, Tag } from '@blueprintjs/core';
import { Classes as Popover2Classes, Popover2, Tooltip2 } from '@blueprintjs/popover2';

import { AppToaster } from '../../../../shared/app-toaster';
import {
  OpportunitiesListResponse,
  TradingPair,
  WICK_CATCHER_STRATEGY_TYPE_NAMES,
  WickCatcher,
  WickCatcherStrategyType,
} from '../../../../shared/interfaces/bot';
import { TimezoneContext } from '../../../../contexts/timezone';

import { WickCatcherAction } from '../../action/action';
import { OpportunitiesList } from '../../../opportunity/list/list';
import { ActiveOrderContext } from '../../../../contexts/active-order';
import { RestRequestTrackerContext } from '../../../../contexts/rest-request-tracker';
import { REST_REQUEST_MINUTE_HIGH } from '../../../../shared/configs';

interface Props {
  item: WickCatcher;
  isLastSelectedItem: boolean;
  onOpen: (item: WickCatcher) => void;
  onEdit: (item: WickCatcher) => void;
  onResume: (wickCatcherId: number) => Promise<any>;
  onClone: (id: number) => Promise<any>;
  onDelete: (id: number) => Promise<any>;
  onReload: (id: number) => Promise<any>;
  onStart: (wickCatcherId: number, mode: object) => Promise<any>;
  onStop: (wickCatcherId: number, mode: object) => Promise<any>;
  onCloseAction: (needToRefresh?: boolean) => void;
  onOpenPair: (item: TradingPair) => void;
  onReloadPair: (id: number) => Promise<any>;
  onSelectPairForFunding: (item: TradingPair) => void;
}

export function WickCatchersListItem({
  item,
  isLastSelectedItem,
  onOpen,
  onEdit,
  onResume,
  onClone,
  onDelete,
  onCloseAction,
  onReload,
  onStart,
  onStop,
  onOpenPair,
  onReloadPair,
  onSelectPairForFunding,
}: Props) {
  const { activeOrders } = useContext(ActiveOrderContext);
  const { restRequestUsage } = useContext(RestRequestTrackerContext);
  const { dateTimeFormater } = useContext(TimezoneContext);
  const [isShortProcessing, setIsShortProcessing] = useState(false);
  const [isLongProcessing, setIsLongProcessing] = useState(false);
  const [selectedPair, setSelectedPair] = useState<TradingPair | undefined>(undefined);

  const handleStart = (id: number, mode: object) => {
    if ('short_mode' in mode) {
      setIsShortProcessing(true);

      return onStart(id, mode).then(() => {
        setIsShortProcessing(false);
      });
    }

    if ('long_mode' in mode) {
      setIsLongProcessing(true);

      return onStart(id, mode).then(() => {
        setIsLongProcessing(false);
      });
    }
  };

  const handleStop = (id: number, mode: object) => {
    if ('short_mode' in mode) {
      setIsShortProcessing(true);

      return onStop(id, mode).then(() => {
        setIsShortProcessing(false);
      });
    }

    if ('long_mode' in mode) {
      setIsLongProcessing(true);

      return onStop(id, mode).then(() => {
        setIsLongProcessing(false);
      });
    }
  };

  const handleOpportunitiesUpdate = (wickCatcherId: number, payload: any) => {
    axios
      .post<OpportunitiesListResponse>(`/api/wick_catchers/${wickCatcherId}/opportunities/update_all`, { opportunities: payload })
      .then(() => {
        onReload(wickCatcherId);

        const message = 'Opportunities updated successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  useEffect(() => {
    if (item.pairs.length <= 0) {
      return setSelectedPair(undefined);
    }

    // If current selectedPair does not exist in the item.pairs list anymore (maybe got deleted after editing)
    // then we make the first pair in the list be active and selected in the tabs list
    if (!selectedPair || item.pairs.indexOf(selectedPair) < 0) {
      setSelectedPair(item.pairs[0]);
    }
  }, [selectedPair, item.pairs]);

  return (
    <Card
      interactive={true}
      elevation={isLastSelectedItem ? 2 : 1}
      key={item.id}
      onDoubleClick={(e) => {
        e.stopPropagation();
        onEdit(item);
      }}
      className="border border-gray-400"
    >
      <div key={item.id} className="wick-catcher h-full flex flex-col">
        <div className="card-header relative">
          <div className="mt-0.5">
            <h2 className="font-bold">{item.name}</h2>

            <div className="mt-1">
              {item.strategy_type === WickCatcherStrategyType.Standard && (
                <span className="font-normal py-1 px-2 bg-pink-800 text-gray-100 text-xs rounded-sm whitespace-pre">
                  {WICK_CATCHER_STRATEGY_TYPE_NAMES[item.strategy_type]}
                </span>
              )}

              {item.strategy_type === WickCatcherStrategyType.InstantArb && (
                <span className="font-normal py-1 px-2 bg-green-500 text-gray-100 text-xs rounded-sm whitespace-pre">
                  {WICK_CATCHER_STRATEGY_TYPE_NAMES[item.strategy_type]}
                </span>
              )}
            </div>
          </div>

          <span className="absolute -top-5 -left-5">{<Tag minimal={true}>{item.id}</Tag>}</span>

          <span className="absolute top-0 right-0">
            <WickCatcherAction
              item={item}
              openItem={onOpen}
              editItem={onEdit}
              resumeItem={onResume}
              cloneItem={onClone}
              deleteItem={onDelete}
              onClose={onCloseAction}
            />
          </span>
        </div>
        <div
          className="card-body mt-2"
          onDoubleClick={() => {
            onOpen(item);
          }}
        >
          <div className="mt-2">
            <ButtonGroup fill={true}>
              {item.short_mode === 'stopped' && (
                <Button
                  className="w-1/2"
                  large={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStart(item.id, { short_mode: 'open_short' });
                  }}
                  loading={isShortProcessing}
                  disabled={isLongProcessing}
                  onDoubleClick={(e) => e.stopPropagation()}
                  intent={Intent.NONE}
                  text="Short"
                />
              )}

              {item.short_mode === 'open_short' && (
                <Button
                  className="w-1/2"
                  large={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStop(item.id, { short_mode: 'stopped' });
                  }}
                  loading={isShortProcessing}
                  disabled={isLongProcessing}
                  onDoubleClick={(e) => e.stopPropagation()}
                  intent={Intent.DANGER}
                  text="Short"
                />
              )}

              {item.long_mode === 'stopped' && (
                <Button
                  className="w-1/2"
                  large={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStart(item.id, { long_mode: 'open_long' });
                  }}
                  loading={isLongProcessing}
                  disabled={isShortProcessing}
                  onDoubleClick={(e) => e.stopPropagation()}
                  intent={Intent.NONE}
                  text="Long"
                />
              )}

              {item.long_mode === 'open_long' && (
                <Button
                  className="w-1/2"
                  large={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStop(item.id, { long_mode: 'stopped' });
                  }}
                  loading={isLongProcessing}
                  disabled={isShortProcessing}
                  onDoubleClick={(e) => e.stopPropagation()}
                  intent={Intent.SUCCESS}
                  text="Long"
                />
              )}
            </ButtonGroup>
          </div>

          <div
            className="mt-7 -ml-5"
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Tabs
              animate={true}
              vertical={true}
              renderActiveTabPanelOnly={false}
              selectedTabId={selectedPair?.id}
              onChange={(newTabId) => {
                let newSelectedPair = item.pairs.find((item) => item.id === newTabId);

                setSelectedPair(newSelectedPair);
              }}
            >
              {item.pairs.map((pair, index) => {
                let effectiveTierForShort = pair.opportunities.find(
                  ({ side, effective_tier }) => side === 'short' && effective_tier,
                )?.effective_tier;

                let effectiveTierForLong = pair.opportunities.find(
                  ({ side, effective_tier }) => side === 'long' && effective_tier,
                )?.effective_tier;

                let fundingIntervalInHours = pair.primary_instrument.funding_interval / 60;

                const pairActiveOrders = activeOrders.filter((order) => order.trading_pair.id === pair.id);
                const pairRestRequestUsage = restRequestUsage[`trading-pair-${pair.id}`];

                return (
                  <Tab
                    id={pair.id}
                    className="pair-tab"
                    key={pair.id}
                    title={
                      <>
                        <div className="flex items-start font-medium text-xs">
                          <div className="mr-1 opacity-40 w-2">{index + 1}</div>

                          <div className="">
                            {!pair.paused_at && <span className="mr-1">{pair.primary_instrument.symbol}</span>}
                            {pair.paused_at && <span className="mr-1 text-red-500">{pair.primary_instrument.symbol}</span>}

                            {pairRestRequestUsage && pairRestRequestUsage.minute >= REST_REQUEST_MINUTE_HIGH && (
                              <Tooltip2
                                content={`This pair sent more than ${REST_REQUEST_MINUTE_HIGH} requests within current minute`}
                                placement="top"
                              >
                                <Icon className="text-yellow-600" icon="flame"></Icon>
                              </Tooltip2>
                            )}

                            <span className="block -mt-1.5 leading-3 mb-1 font-medium text-xxs">
                              {effectiveTierForShort && effectiveTierForShort.tier_settings.tier > 0 && (
                                <Tooltip2 content="Effective Tier for Short side" placement="top">
                                  <span className="mr-1 text-gray-900">S{effectiveTierForShort.tier_settings.tier}</span>
                                </Tooltip2>
                              )}

                              {effectiveTierForShort && effectiveTierForShort.tier_settings.tier <= 0 && (
                                <Tooltip2 content="Effective Tier for Short side" placement="top">
                                  <span className="mr-1 text-gray-900 opacity-60">S{effectiveTierForShort.tier_settings.tier}</span>
                                </Tooltip2>
                              )}

                              {effectiveTierForLong && effectiveTierForLong.tier_settings.tier > 0 && (
                                <Tooltip2 content="Effective Tier for Long side" placement="top">
                                  <span className="mr-1 text-gray-900">L{effectiveTierForLong.tier_settings.tier}</span>
                                </Tooltip2>
                              )}

                              {effectiveTierForLong && effectiveTierForLong.tier_settings.tier <= 0 && (
                                <Tooltip2 content="Effective Tier for Long side" placement="top">
                                  <span className="mr-1 text-gray-900 opacity-60">L{effectiveTierForLong.tier_settings.tier}</span>
                                </Tooltip2>
                              )}

                              <Tooltip2 content="Total active orders for this pair" placement="top">
                                <span className="mr-1 text-gray-900 opacity-60">{pairActiveOrders.length}</span>
                              </Tooltip2>

                              <Tooltip2 content="Total REST requests made by this pair in the current minute period" placement="top">
                                <span className="mr-1 text-gray-900 opacity-60">{pairRestRequestUsage?.minute}</span>
                              </Tooltip2>
                            </span>
                          </div>
                        </div>
                      </>
                    }
                    panel={
                      <div key={pair.id} className="mt-0">
                        <div>
                          <div className="inline-block">
                            <ButtonGroup>
                              <Tooltip2 content="Double-click to see pair details" placement="top" hoverOpenDelay={420}>
                                <Button
                                  icon="cube"
                                  small={true}
                                  // minimal={true}
                                  className="text-black"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                  onDoubleClick={(e) => {
                                    e.stopPropagation();
                                    onOpenPair(pair);
                                  }}
                                >
                                  <span>{pair.primary_instrument.symbol}</span>

                                  <span className="mx-3 opacity-70">
                                    <Icon icon="arrow-right" size={15}></Icon>
                                  </span>

                                  <span>{pair.secondary_instrument.symbol}</span>

                                  <span className="ml-1 opacity-50"> #{pair.id}</span>
                                </Button>
                              </Tooltip2>
                            </ButtonGroup>

                            <ButtonGroup className="ml-3">
                              <Button icon="rocket-slant" small={true} className="text-black">
                                Requests
                              </Button>

                              <Tooltip2 content="Total REST requests made by this pair in the current MINUTE period" placement="top">
                                <Button small={true}>{pairRestRequestUsage?.minute || '-'}</Button>
                              </Tooltip2>

                              <Tooltip2 content="Total REST requests made by this pair in the current HOUR period" placement="top">
                                <Button small={true}>{pairRestRequestUsage?.hour || '-'}</Button>
                              </Tooltip2>

                              <Tooltip2 content="Total REST requests made by this pair in the current DAY period" placement="top">
                                <Button small={true}>{pairRestRequestUsage?.day || '-'}</Button>
                              </Tooltip2>
                            </ButtonGroup>
                          </div>

                          <div className="inline-block float-right">
                            <ButtonGroup className="ml-3">
                              {pair.paused_at && (
                                <Popover2
                                  interactionKind="hover"
                                  popoverClassName={Popover2Classes.POPOVER2_CONTENT_SIZING}
                                  enforceFocus={false}
                                  content={
                                    <div>
                                      <H5>{pair.paused_reason?.code}</H5>

                                      <H6>Message</H6>
                                      <p>{pair.paused_reason?.message}</p>

                                      <H6>Paused at</H6>
                                      <p>{dateTimeFormater.format(new Date(pair.paused_at + 'Z'))}</p>

                                      {pair.paused_reason?.action_needed && (
                                        <>
                                          <H6>Action Needed:</H6>
                                          <p>{pair.paused_reason?.action_needed}</p>
                                        </>
                                      )}
                                    </div>
                                  }
                                  minimal={false}
                                  position={Position.TOP}
                                  hoverCloseDelay={1200}
                                >
                                  <>
                                    <Button icon="stop" small={true} intent={Intent.DANGER} outlined={true} active={true}>
                                      PAUSED
                                    </Button>
                                  </>
                                </Popover2>
                              )}

                              {fundingIntervalInHours >= 8 && (
                                <Tooltip2 content="Funding interval" placement="top" hoverOpenDelay={420}>
                                  <Button icon="social-media" small={true} outlined={true} onClick={() => onSelectPairForFunding(pair)}>
                                    {fundingIntervalInHours} hours
                                  </Button>
                                </Tooltip2>
                              )}

                              {fundingIntervalInHours < 8 && (
                                <Tooltip2 content="Funding interval" placement="top" hoverOpenDelay={420}>
                                  <Button
                                    icon="social-media"
                                    small={true}
                                    outlined={false}
                                    intent={Intent.WARNING}
                                    onClick={() => onSelectPairForFunding(pair)}
                                  >
                                    {fundingIntervalInHours} hours
                                  </Button>
                                </Tooltip2>
                              )}
                            </ButtonGroup>
                          </div>
                        </div>

                        <p className="my-2">
                          {effectiveTierForShort && (
                            <span className=" text-black font-medium text-xs">
                              <span className="mr-2 py-0.5 px-1.5 rounded-md bg-red-300 text-gray-700">
                                {effectiveTierForShort.tier_settings.short_multiplier}x
                              </span>

                              <span className="mr-2 py-0.5 px-1.5 rounded-md bg-gray-300 text-gray-800">
                                Tier {effectiveTierForShort.tier_settings.tier}
                              </span>

                              <span className="mr-2 py-0.5 px-1.5 rounded-md bg-gray-300 text-gray-800">
                                {'>='} {effectiveTierForShort.tier_settings.trigger_threshold}%
                              </span>

                              <span className="mr-2 py-0.5 px-1.5 rounded-md bg-gray-300 text-gray-800">
                                {effectiveTierForShort.trigger_market}{' '}
                                {effectiveTierForShort.type
                                  .split('_')
                                  .map((word) => _.upperFirst(word))
                                  .join(' ')}
                              </span>
                            </span>
                          )}
                        </p>

                        <p className="my-2">
                          {effectiveTierForLong && (
                            <span className=" text-black font-medium text-xs">
                              <span className="mr-2 py-0.5 px-1.5 rounded-md bg-green-400 text-gray-700">
                                {effectiveTierForLong.tier_settings.long_multiplier}x
                              </span>

                              <span className="mr-2 py-0.5 px-1.5 rounded-md bg-gray-300 text-gray-800">
                                Tier {effectiveTierForLong.tier_settings.tier}
                              </span>

                              <span className="mr-2 py-0.5 px-1.5 rounded-md bg-gray-300 text-gray-800">
                                {'>='} {effectiveTierForLong.tier_settings.trigger_threshold}%
                              </span>

                              <span className="mr-2 py-0.5 px-1.5 rounded-md bg-gray-300 text-gray-800">
                                {effectiveTierForLong.trigger_market}{' '}
                                {effectiveTierForLong.type
                                  .split('_')
                                  .map((word) => _.upperFirst(word))
                                  .join(' ')}
                              </span>
                            </span>
                          )}
                        </p>

                        <div className="mt-1">
                          <OpportunitiesList
                            key={pair.id}
                            tradingPair={pair}
                            onUpdate={handleOpportunitiesUpdate}
                            onReloadPair={onReloadPair}
                            minimal={true}
                          />
                        </div>
                      </div>
                    }
                  />
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
    </Card>
  );
}
