import { useEffect, useState } from 'react';
import axios from '../../../shared/custom-axios';
import { Button, ButtonGroup, Classes, H5, Icon, Intent, Popover, PopoverPosition, Switch } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import { AppToaster } from '../../../shared/app-toaster';
import { Schedule, SchedulesListResponse, ScheduleResponse, ScheduleStatus } from '../../../shared/interfaces/schedule';

import { formatNextExecutionDateTime } from '../form/form';

export function ScheduleList() {
  let navigate = useNavigate();
  const [items, setItems] = useState<Schedule[]>([]);

  useEffect(() => {
    axios.get<SchedulesListResponse>('/api/scheduled_tasks').then((response) => {
      setItems(response.data.data);
    });
  }, []);

  const handleActivate = (itemId: number) => {
    axios
      .post<ScheduleResponse>(`/api/scheduled_tasks/${itemId}/activate`)
      .then((resp) => {
        setItems((prevState) => {
          let item = resp.data.data;
          console.log(prevState, 'prevState');
          const newState = prevState.map((currentItem) => {
            if (itemId === currentItem.id) {
              return item;
            }

            return currentItem;
          });
          console.log(newState, 'newState');
          return newState;
        });

        const message = 'Schedule activated!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleDeactivate = (itemId: number) => {
    axios
      .post<ScheduleResponse>(`/api/scheduled_tasks/${itemId}/deactivate`)
      .then((resp) => {
        setItems((prevState) => {
          let item = resp.data.data;
          const newState = prevState.map((currentItem) => {
            if (itemId === currentItem.id) {
              return item;
            }

            return currentItem;
          });
          return newState;
        });

        const message = 'Schedule deactivated!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleDelete = (itemId: number) => {
    axios
      .delete<SchedulesListResponse>(`/api/scheduled_tasks/${itemId}`)
      .then(() => {
        setItems((prevState) => {
          const newState = prevState.filter(({ id }) => id !== itemId);
          return newState;
        });

        const message = 'Schedule deleted successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleEdit = (id: number) => {
    navigate(`/schedules/${id}/edit`);
  };

  return (
    <>
      <Link to={'/schedules/new'} className="mt-1 bp4-button">
        <Icon icon="add" />
        <div>New</div>
      </Link>

      <table className="mt-5 bp4-html-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Occurrence</th>
            <th>One-time Schedule</th>
            <th>Recurring Schedule</th>
            <th>Next Execution</th>
            <th>Timezone</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {items.map((item) => {
            return (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.occurrence}</td>
                <td>{item.run_once_at}</td>
                <td>{item.run_at_prompt}</td>
                <td>{item.status === ScheduleStatus.Activated && formatNextExecutionDateTime(item.next_executions[0], item.timezone)}</td>
                <td>{item.timezone + ` (UTC${moment.tz(item.timezone).format('Z')})`}</td>
                <td>
                  <Switch
                    checked={item.status === ScheduleStatus.Activated}
                    label="Activated"
                    onChange={() => {
                      if (item.status === ScheduleStatus.Deactivated) {
                        return handleActivate(item.id);
                      }

                      return handleDeactivate(item.id);
                    }}
                  />
                </td>
                <td>
                  <ButtonGroup>
                    <Button className="mr-1" icon="edit" intent={Intent.NONE} small={true} onClick={() => handleEdit(item.id)}>
                      Edit
                    </Button>

                    <Popover popoverClassName={Classes.POPOVER_CONTENT_SIZING} position={PopoverPosition.RIGHT}>
                      <Button intent={Intent.NONE} icon="trash" small={true} disabled={item.status === ScheduleStatus.Activated}>
                        Delete
                      </Button>

                      <div key="text">
                        <H5>Confirm deletion</H5>
                        <p>Are you sure you want to delete this item?</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                          <Button className={Classes.POPOVER_DISMISS} style={{ marginRight: 10 }}>
                            Cancel
                          </Button>

                          <Button onClick={() => handleDelete(item.id)} intent={Intent.DANGER} className={Classes.POPOVER_DISMISS}>
                            Delete
                          </Button>
                        </div>
                      </div>
                    </Popover>
                  </ButtonGroup>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}
