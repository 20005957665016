import React, { useContext } from 'react';
import _ from 'lodash';
import { Intent, Tag } from '@blueprintjs/core';
import { Classes, Tooltip2 } from '@blueprintjs/popover2';

import { ExchangeType, StrategyType, TradeFeedOrderFill, TradingPair } from '../../../../shared/interfaces/bot';
import { copyToClipboard, countDecimalPlaces } from '../../../../shared/utils';
import { TimezoneContext } from '../../../../contexts/timezone';

import { TradeFeedOrderFillsListTable } from './table';
import { TradeFeedOrderFillLagTimeDetails } from '../lag-time-details/lag-time-details';

interface Props {
  items: TradeFeedOrderFill[];
  tradingPair: TradingPair;
}

export function TradeFeedOrderFillsList({ items, tradingPair }: Props) {
  const { dateFormater, timeFormater } = useContext(TimezoneContext);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (order.fill_tx_ts) {
            const dateTime = new Date(order.fill_tx_ts + 'Z');

            return (
              <Tooltip2
                hoverOpenDelay={420}
                content="Transaction Date (source: exchange)"
                placement="top"
                className={Classes.TOOLTIP2_INDICATOR}
              >
                {dateFormater.format(dateTime)}
              </Tooltip2>
            );
          }
        },
        minWidth: 128,
        maxWidth: 128,
      },
      {
        Header: 'Fill Time',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (order.fill_tx_ts) {
            const dateTime = new Date(order.fill_tx_ts + 'Z');

            return (
              <Tooltip2
                hoverOpenDelay={420}
                content="Transaction Date (source: exchange)"
                placement="top"
                className={Classes.TOOLTIP2_INDICATOR}
              >
                {timeFormater.format(dateTime)}
              </Tooltip2>
            );
          }
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Lag Time (ms)',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.fill_tx_ts) {
            return '';
          }

          const isDefiTaking = tradingPair.primary_instrument.exchange_type === ExchangeType.DEX;
          return <TradeFeedOrderFillLagTimeDetails isDeFiTaking={isDefiTaking} orderFill={order} />;
        },
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Side',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <div className="text-center flex flex-row">
              {order.side == 'buy' && (
                <Tag minimal={true} intent={Intent.SUCCESS}>
                  {_.upperFirst(order.side)}
                </Tag>
              )}

              {order.side == 'sell' && (
                <Tag minimal={true} intent={Intent.DANGER}>
                  {_.upperFirst(order.side)}
                </Tag>
              )}

              {order.opportunity.is_flip && (
                <Tag minimal={true} intent={Intent.PRIMARY}>
                  Flip
                </Tag>
              )}
            </div>
          );
        },
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: 'Trade ID',
        id: 'trade_id',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.trade_id} placement="top">
              <span className="cursor-pointer" onClick={() => order.trade_id && copyToClipboard(order.trade_id)}>
                {order.trade_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'External ID',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.external_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.external_id)}>
                {order.external_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Internal ID',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.internal_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.internal_id)}>
                {order.internal_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Placement',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (order.placement_logic && order.placement_logic['wick_catcher']) {
            return order.placement_logic['wick_catcher'];
          }

          return '';
        },
      },
      {
        Header: 'Tier',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          let effective_tier = order.opportunity.effective_tier;

          if (effective_tier) {
            let { type, trigger_market, tier_settings } = effective_tier;

            return (
              <>
                <span className="block">
                  <Tooltip2 content={JSON.stringify(tier_settings)} placement="top">
                    <span>Tier {tier_settings.tier}</span>
                  </Tooltip2>
                </span>

                <span className="block">{trigger_market}</span>

                <span className="block">{type}</span>
              </>
            );
          }

          return '';
        },
      },
      {
        Header: 'Avg Fill Price',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.avg_fill_price?.amount) {
            return;
          }

          return `${order.avg_fill_price?.amount} ${order.avg_fill_price?.currency}`;
        },
        minWidth: 150,
        maxWidth: 150,
      },
      {
        Header: 'Fill Price',
        disableSortBy: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <>
              <span className="block">
                {order.last_fill_price?.amount && (
                  <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                    {order.last_fill_price?.amount}
                  </span>
                )}
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_price?.currency}</span>
            </>
          );
        },
        minWidth: 150,
        maxWidth: 150,
      },
      {
        Header: 'Fill Volume / Total Volume',
        disableSortBy: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          const isFull =
            order.last_fill_volume?.amount &&
            order.original_volume?.amount &&
            order.last_fill_volume?.amount >= order.original_volume?.amount;

          if (isFull) {
            return (
              <>
                <span className="font-semibold py-1 px-2 border border-gray-400 bg-gray-300 text-gray-900 rounded-xl whitespace-pre">
                  {order.last_fill_volume?.amount ? order.last_fill_volume?.amount : '-'}
                  {' / '}

                  {order.original_volume?.amount ? `${order.original_volume?.amount}` : '-'}
                </span>

                <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_volume?.currency}</span>
              </>
            );
          }

          return (
            <>
              <span className="block">
                <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                  {order.last_fill_volume?.amount ? order.last_fill_volume?.amount : '-'}
                  {' / '}
                  {order.original_volume?.amount ? ` / ${order.original_volume?.amount}` : '-'}
                </span>
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_volume?.currency}</span>
            </>
          );
        },
        minWidth: 150,
        maxWidth: 150,
      },
      {
        Header: 'Fill Value',
        disableSortBy: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <>
              <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                {order.last_fill_value?.amount}
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_value?.currency}</span>
            </>
          );
        },
        minWidth: 150,
        maxWidth: 150,
      },
      {
        Header: 'Achieved',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!_.isNumber(order.achieved)) {
            return;
          }

          let achieved = order.achieved;

          const profitableSell = order.side === 'sell' && achieved > 0;
          const unProfitableSell = order.side === 'sell' && achieved < 0;
          const profitableBuy = order.side === 'buy' && achieved < 0;
          const unProfitableBuy = order.side === 'buy' && achieved > 0;

          switch (order.trading_pair.strategy_type) {
            case StrategyType.PairsTrading: {
              let ratioDecimalsCount = countDecimalPlaces(order.opportunity.ratio);
              let achievedText = achieved.toFixed(ratioDecimalsCount);

              // For PairsTrading, we style Achieved column the same as Side column, not based on profitability
              if (order.side === 'buy') {
                return (
                  <span className="text-green-600 font-bold" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              return (
                <span className="text-red-500" title={achieved.toString()}>
                  {achievedText}
                </span>
              );
            }

            case StrategyType.WickCatcher_Standard: {
              achieved = Number(achieved.toFixed(2));
              const profitable = achieved > 0;
              const unprofitable = achieved < 0;
              let achievedText = achieved + '%';

              if (profitable) {
                return (
                  <span className="text-green-600 font-bold" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              if (unprofitable) {
                return (
                  <span className="text-red-500" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              return (
                <span className="font-bold" title={achieved.toString()}>
                  {achievedText}
                </span>
              );
            }

            default: {
              let achievedText = achieved.toFixed(2) + '%';

              if (profitableSell || profitableBuy) {
                return (
                  <span className="text-green-600 font-bold" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              if (unProfitableSell || unProfitableBuy) {
                return (
                  <span className="text-red-500" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              return (
                <span className="font-bold" title={achieved.toString()}>
                  {achievedText}
                </span>
              );
            }
          }
        },
        minWidth: 120,
      },
    ],
    [dateFormater, timeFormater],
  );

  return <>{items && <TradeFeedOrderFillsListTable columns={columns} data={items} />}</>;
}
