import { useCallback, useEffect, useState } from 'react';
import axios from '../../shared/custom-axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';

import { AppToaster } from '../../shared/app-toaster';
import { ScheduleForm } from '../../components/schedule/form/form';
import { Schedule, ScheduleResponse } from '../../shared/interfaces/schedule';

export const ScheduleEditPage = (props: any) => {
  let navigate = useNavigate();
  let { id } = useParams<{ id: string }>();

  const [item, setItem] = useState<Schedule | null>(null);

  useEffect(() => {
    axios
      .get<ScheduleResponse>(`/api/scheduled_tasks/${id}`)
      .then((response) => {
        const newData = response.data.data;
        setItem(newData);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, [id]);

  const handleCreate = useCallback((formState: object) => {
    axios
      .post<Schedule[]>('/api/scheduled_tasks', { scheduled_task: formState })
      .then(() => {
        navigate('/schedules');
        const message = 'Schedule created successfully!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        let message;

        if (error.response.data.errors) {
          message = JSON.stringify(error.response.data.errors);
        } else {
          message = error.response.data.error_message;
        }

        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleUpdate = useCallback((accountId: number, formState: object) => {
    axios
      .put<ScheduleResponse>(`/api/scheduled_tasks/${accountId}`, { scheduled_task: formState })
      .then((response) => {
        // TODO: Consider redirect to details page of given account instead (details page is not yet implemented)
        navigate('/schedules');

        const message = 'Schedule updated';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        let message;

        if (error.response.data.errors) {
          message = JSON.stringify(error.response.data.errors);
        } else {
          message = error.response.data.error_message;
        }

        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  const handleActivate = (itemId: number) => {
    axios
      .post<ScheduleResponse>(`/api/scheduled_tasks/${itemId}/activate`)
      .then((resp) => {
        let item = resp.data.data;
        setItem(item);

        const message = 'Schedule deactivated!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  const handleDeactivate = (itemId: number) => {
    axios
      .post<ScheduleResponse>(`/api/scheduled_tasks/${itemId}/deactivate`)
      .then((resp) => {
        let item = resp.data.data;
        setItem(item);

        const message = 'Schedule deactivated!';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  };

  return (
    <>
      <h2 className="text-lg font-bold my-1">Edit schedule</h2>

      <ScheduleForm
        item={item}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onActivate={handleActivate}
        onDeactivate={handleDeactivate}
      />
    </>
  );
};
