import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, matchPath, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ButtonGroup, Classes, Icon, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';

import { Bot, BotState } from '../../shared/interfaces/bot';
import { BotsManagerContext } from '../../contexts/bots-manager';
import { BotsHealthContext } from '../../contexts/bots-health';
import { ConnectivityIndicator } from '../bot/connectivity-indicator';
import styles from '../navigation/navigation.module.css';
import { APP_NAME } from '../../shared/configs';
import { BotStopButton } from '../common/bot-stop-button/bot-redis-client-restart-button';
import { TradingPairOmnibar } from '../common/trading-pair-omnibar/trading-pair-omnibar';
import { NavigationUserMenu } from '../navigation/user-menu/user-menu';
import { WickCatcherCreateDialog } from '../wick-catcher/create-dialog/create-dialog';
import { TradingPairCreateDialog } from '../trading-pair/create-dialog/create-dialog';
import { CancelAllButton } from '../cancel-all/button';
import orderService from '../../shared/order-service';

export default function Sidebar() {
  const { bots, setCurrentBot } = useContext(BotsManagerContext);
  const { botsHealthChecks } = useContext(BotsHealthContext);
  const navigate = useNavigate();

  const [openWickCatcherCreatePopup, setOpenWickCatcherCreatePopup] = useState<boolean>(false);
  const [openTradingPairCreatePopup, setOpenTradingPairCreatePopup] = useState<boolean>(false);

  let location = useLocation();

  const [newOrderFills, setNewOrderFills] = useState<number>(0);

  const handleNewOrderFill = useCallback(() => {
    const match = matchPath(location.pathname, '/trade-feed');

    if (!match) {
      setNewOrderFills(orderService.orderFills.length);
    }
  }, [location, setNewOrderFills]);

  useEffect(() => {
    orderService.hedgeEvent.on('primary:order_fill:init', handleNewOrderFill);

    return () => {
      orderService.hedgeEvent.off('primary:order_fill:init', handleNewOrderFill);
    };
  }, []);

  return (
    <aside className="fixed left-0 top-0 bottom-0 w-56 border-r bg-white p-4 z-10">
      <NavLink
        to={`/bots`}
        onClick={() => setCurrentBot && setCurrentBot(null)}
        className={`bp4-button hover:no-underline ${styles['app-name']} w-full`}
      >
        <h1 className="font-extrabold">{APP_NAME}</h1>
      </NavLink>

      <div className="mt-2 w-full">
        <NavigationUserMenu />
      </div>

      <nav className="space-y-2 z-50 mt-3">
        <Menu className={Classes.ELEVATION_1}>
          <TradingPairOmnibar>
            <Icon icon="search" />
            <span>Search for pairs</span>
          </TradingPairOmnibar>

          <NavLink
            to={'/trade-feed'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="th-filtered" />
            <div style={{ position: 'relative' }}>
              Trade Feed
              {newOrderFills && newOrderFills > 0 ? <span className="badge-content -top-3.5 -right-5">{newOrderFills}</span> : null}
            </div>
          </NavLink>

          <NavLink
            to={'/accounts'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="key" />
            <div>Accounts</div>
          </NavLink>

          <MenuItem icon="exchange" text="Markets Info">
            <MenuDivider title="CEXs & DEXs" />

            <li>
              <Link to={'/instruments'} className="bp4-menu-item bp4-popover-dismiss">
                <Icon icon="book" />
                <div>Instruments</div>
              </Link>
            </li>

            <MenuDivider title="DEXs-specific" />

            <li>
              <Link to={'/networks'} className="bp4-menu-item bp4-popover-dismiss">
                <Icon icon="globe-network" />
                <div>Networks</div>
              </Link>
            </li>

            <li>
              <Link to={'/dexes'} className="bp4-menu-item bp4-popover-dismiss">
                <Icon icon="exchange" />
                <div>DEXes</div>
              </Link>
            </li>
          </MenuItem>

          <Link
            to={'/bots/metrics'}
            className={'bp4-menu-item ' + Classes.POPOVER_DISMISS}
            onClick={() => setCurrentBot && setCurrentBot(null)}
          >
            <Icon icon="timeline-line-chart" />
            <div>Apps Metrics</div>
          </Link>

          <NavLink
            to={'/schedules'}
            className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
          >
            <Icon icon="timeline-events" />
            <div>Schedules</div>
          </NavLink>

          <MenuDivider title="Bots" />

          <div>
            {bots.map((bot: Bot) => {
              return (
                <MenuItem
                  key={bot.id}
                  className="py-3 border-b last:border-b-0"
                  text={
                    <div className="relative">
                      <div>
                        <span className="absolute top-0 -left-1">
                          <ConnectivityIndicator active={botsHealthChecks[bot.id]?.connectivity_status === BotState.Connected} />
                        </span>

                        <span className="ml-4">{bot.name}</span>
                      </div>

                      <div className="mt-2">
                        <ButtonGroup className="mr-3" fill={false}>
                          <BotStopButton bot={bot} />
                        </ButtonGroup>
                      </div>
                    </div>
                  }
                  onClick={() => {
                    console.log(bot, 'AAA');
                    setCurrentBot && setCurrentBot(bot);
                    navigate(`/bots/${bot.app_id}/wick_catchers`);
                  }}
                >
                  <MenuDivider title="Wick Catchers" />

                  <MenuItem icon="plus" text="Create New" onClick={() => setOpenWickCatcherCreatePopup(true)} />

                  <NavLink
                    to={`/bots/${bot.app_id}/wick_catchers`}
                    className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
                  >
                    <Icon icon="multi-select" />
                    <div>List All</div>
                  </NavLink>

                  <MenuDivider title="Trading Pairs" />

                  <MenuItem icon="plus" text="Create New" onClick={() => setOpenTradingPairCreatePopup(true)} />

                  <NavLink
                    to={`/bots/${bot.app_id}/trading_pairs`}
                    className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
                  >
                    <Icon icon="multi-select" />
                    <div>List All</div>
                  </NavLink>

                  <MenuDivider title="Grandparent resources" />

                  <NavLink
                    to={`/bots/${bot.app_id}/order_books`}
                    className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
                  >
                    <Icon icon="join-table" />
                    <div>Order Books</div>
                  </NavLink>

                  <NavLink
                    to={`/bots/${bot.app_id}/private_streams`}
                    className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
                  >
                    <Icon icon="one-to-many" />
                    <div>Private Streams</div>
                  </NavLink>

                  <NavLink
                    to={`/bots/${bot.app_id}/contract_streams `}
                    className={({ isActive }) => `${isActive ? 'bp4-active' : ''} bp4-menu-item ${Classes.POPOVER_DISMISS}`}
                  >
                    <Icon icon="one-to-many" />
                    <div>Contract Streams</div>
                  </NavLink>
                </MenuItem>
              );
            })}
          </div>

          <div className="mt-3">
            <CancelAllButton fill={true} small={true}></CancelAllButton>
          </div>

          <Link
            to={'/bots'}
            className={'mt-1 bp4-menu-item ' + Classes.POPOVER_DISMISS}
            onClick={() => setCurrentBot && setCurrentBot(null)}
          >
            <div>Manage Bots</div>
          </Link>
        </Menu>
      </nav>

      <WickCatcherCreateDialog isVisible={openWickCatcherCreatePopup} setVisible={setOpenWickCatcherCreatePopup} />

      <TradingPairCreateDialog isVisible={openTradingPairCreatePopup} setVisible={setOpenTradingPairCreatePopup} />
    </aside>
  );
}
