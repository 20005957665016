import { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';
import { Channel } from 'phoenix';
import socket from '../../socket';

import { BinanceRateLimit } from '../../shared/interfaces/bot';
import { critialErrorSound } from '../../sounds/index';
import { AppRightToaster } from '../../shared/app-toaster';
import orderService from '../../shared/order-service';
import { BotsManagerContext } from '../../contexts/bots-manager';
import { RateLimitContext } from '../../contexts/rate-limit-info';
import { ScrollTargetPairContext } from '../../contexts/scroll-target-pair';

export interface Params {
  appId: string;
}

export default function PageBody({ children }: any) {
  let { appId } = useParams<keyof Params>();

  const { bots, setCurrentBot } = useContext(BotsManagerContext);
  const { setTargetPairId } = useContext(ScrollTargetPairContext);
  const { accountRateLimits, setAccountRateLimits } = useContext(RateLimitContext);

  let notificationChannel = useRef<Channel | null>(null);

  useEffect(() => {
    notificationChannel.current = socket.channel('notification:lobby', { from: 'PageBody' });

    notificationChannel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[PageBody] Joined "notification:lobby" channel for notification real-time updates`);
      })
      .receive('error', (resp) => {
        console.log('[PageBody] Cannot join "notification:lobby"', resp);
      });

    notificationChannel.current?.onClose(() => {
      console.log('[PageBody] Left "notification:lobby"');
    });

    notificationChannel.current?.on('notification:message', ({ data }) => {
      const { message } = data;

      console.log(data);

      // // Play the sound non-stop until users click dismiss the toaster below
      // critialErrorSound.loop = false;
      // critialErrorSound.volume = 1;
      // critialErrorSound.play().then();

      // const message = (
      //   <div>
      //     <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
      //     <div className="mb-2">Auto stopping pairs and cancel all orders due to a critical error:</div>

      //     <div className="mb-2">Exchange: {exchange}</div>

      //     {account && (
      //       <div className="mb-2">
      //         Account triggered: <span className="font-bold">{account['name']}</span> [#{account['id']}]
      //       </div>
      //     )}

      //     <span>{JSON.stringify(error)}</span>
      //   </div>
      // );

      AppRightToaster.show({
        message: message,
        icon: 'warning-sign',
        intent: Intent.PRIMARY,
        timeout: 0,
        isCloseButtonShown: true,
        onDismiss: () => {
          critialErrorSound.pause();
        },
      });
    });

    notificationChannel.current?.on('notification:system_monitor', ({ data }) => {
      const { app, exchange, account, error } = data;

      // Play the sound non-stop until users click dismiss the toaster below
      critialErrorSound.loop = false;
      critialErrorSound.volume = 1;
      critialErrorSound.play().then();

      const message = (
        <div>
          <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
          <div className="mb-2">Auto stopping pairs and cancel all orders due to a critical error:</div>

          <div className="mb-2">Exchange: {exchange}</div>

          {account && (
            <div className="mb-2">
              Account triggered: <span className="font-bold">{account['name']}</span> [#{account['id']}]
            </div>
          )}

          <span>{JSON.stringify(error)}</span>
        </div>
      );

      AppRightToaster.show({
        message: message,
        icon: 'warning-sign',
        intent: Intent.DANGER,
        timeout: 0,
        isCloseButtonShown: true,
        onDismiss: () => {
          critialErrorSound.pause();
        },
      });
    });

    notificationChannel.current?.on('notification:error_message', ({ data }) => {
      const { type, app, wick_catcher_id, trading_pair_id, trading_pair, error } = data;

      switch (type) {
        case 'order_book_worker': {
          const { order_book } = data;
          let message = `${JSON.stringify(error)}`;

          AppRightToaster.show({
            message: (
              <div>
                <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
                <div className="mb-2">
                  {order_book && <h5>Order Book ID: #{order_book.id}</h5>}
                  {order_book && (
                    <h5>
                      Instrument: #{order_book.instrument?.id} {order_book.instrument?.symbol}
                    </h5>
                  )}
                </div>

                <p>Order Book worker got a WebSocket error:</p>

                <p>{message}</p>
              </div>
            ),
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 10000,
          });

          break;
        }

        case 'bbo_worker': {
          const { order_book } = data;
          let message = `${JSON.stringify(error)}`;

          AppRightToaster.show({
            message: (
              <div>
                <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
                <div className="mb-2">
                  {order_book && <h5>Order Book ID: #{order_book.id}</h5>}
                  {order_book && (
                    <h5>
                      Instrument: #{order_book.instrument?.id} {order_book.instrument?.symbol}
                    </h5>
                  )}
                </div>

                <p>BBO worker got a WebSocket error:</p>

                <p>{message}</p>
              </div>
            ),
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 10000,
          });

          break;
        }

        case 'mark_price_worker': {
          let message = `${JSON.stringify(error)}`;

          AppRightToaster.show({
            message: (
              <div>
                <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>

                <p>Mark Price worker got a WebSocket error:</p>

                <p>{message}</p>
              </div>
            ),
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 10000,
          });

          break;
        }

        case 'index_price_worker': {
          let message = `${JSON.stringify(error)}`;

          AppRightToaster.show({
            message: (
              <div>
                <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>

                <p>Index Price worker got a WebSocket error:</p>

                <p>{message}</p>
              </div>
            ),
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 10000,
          });

          break;
        }

        default: {
          // TODO: Migrate following if statement to a dedicated case clause in the switch case above
          // Need to support "type" field for this case on Spread side
          if (trading_pair_id) {
            let message = `${JSON.stringify(error)}`;

            if (error === 'exceed_order_volume_threshold') {
              orderService.handleOpportunityExceedVolumeThreshold(data['opportunity_id']);
              message = `Opportunity ${data['opportunity_id']} exceed the volume threshold`;
            }

            if (error === 'exceed_max_volume') {
              orderService.handleSkipHedging(trading_pair_id, data['opportunity_id']);
              message = `Skip hedging because of exceeding the max volume`;
            }

            if (orderService.isRateLimitError(error)) {
              orderService.handleOpportunityExceedRateLimit({
                tradingPairId: trading_pair_id,
                opportunityId: data['opportunity_id'],
                error: error,
              });
              return;
            } else if (data['opportunity_id']) {
              orderService.clearOpportunityExceedRateLimit([data['opportunity_id']]);
            }

            AppRightToaster.show({
              message: (
                <div className="clickable" onClick={() => setTargetPairId(trading_pair_id)}>
                  <div className="mb-2">{app && <h5>Bot: {app.name}</h5>}</div>
                  <div className="mb-2">
                    {wick_catcher_id && <h5>Wick Catcher: #{wick_catcher_id}</h5>}
                    {trading_pair && (
                      <h5>
                        Pair: #{trading_pair.id} {trading_pair.name}
                      </h5>
                    )}
                  </div>

                  <span>{message}</span>
                </div>
              ),
              icon: 'warning-sign',
              intent: Intent.DANGER,
              timeout: 7000,
              // action: {
              //   onClick: () => {
              //     setTargetPairId(trading_pair_id);
              //   },
              //   text: 'Open trading pair',
              // },
            });

            return;
          }

          const message = JSON.stringify(error);

          AppRightToaster.show({
            message: message,
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 7000,
          });
        }
      }
    });

    notificationChannel.current?.on('notification:rate_limit_info', ({ data }) => {
      const { message } = data;

      if (message.main_exchange === 'binance' && setAccountRateLimits) {
        let updatedRateLimits = [];
        const index = accountRateLimits.findIndex((ratelimit: BinanceRateLimit) => ratelimit.account_id === message.account_id);
        if (index >= 0) {
          updatedRateLimits = accountRateLimits.splice(index, 1, message);
        } else {
          updatedRateLimits = accountRateLimits.concat(message);
        }
        setAccountRateLimits(updatedRateLimits);
      }
    });

    return () => {
      notificationChannel.current?.leave();
    };
  }, []);

  useEffect(() => {
    // Select current bot based on URL when reloading the page
    const bot = bots.find((bot) => bot.app_id === appId);

    bot && setCurrentBot && setCurrentBot(bot);
  }, [appId, bots, setCurrentBot]);

  return <>{children}</>;
}
