import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Classes,
  ControlGroup,
  Dialog,
  Divider,
  FormGroup,
  H4,
  H6,
  HTMLSelect,
  Icon,
  InputGroup,
  Intent,
  NumericInput,
  Position,
  Pre,
  Radio,
  RadioGroup,
  Tag,
  TextArea,
} from '@blueprintjs/core';
import _ from 'lodash';
import { Classes as PopoverClasses, Popover2 } from '@blueprintjs/popover2';
import { DatePicker, TimePrecision } from '@blueprintjs/datetime';
import moment from 'moment-timezone';

import axios from '../../../shared/custom-axios';
import { TimezoneSelect } from '../../common/timezone-select/timezone-select';
import { AppToaster } from '../../../shared/app-toaster';
import { ProjectedUpcomingRuns } from './projected-upcoming-runs';
import {
  MarketAvailabilityRule,
  ExchangesDiffsConditionListing,
  Schedule,
  ScheduleOccurrence,
  ScheduleStatus,
  StartStopSideTaskAction,
  TaskAction,
  TaskActionParamsMap,
  TaskActionType,
  ScheduleWebhookAuthType,
  ScheduleWebhook,
} from '../../../shared/interfaces/schedule';
import { ExchangesInfoContext } from '../../../contexts/exchanges-info';
import { CoinsDiffsList } from './coins-diffs-list';
import { actions } from 'react-table';

// UTC+8, Bali time
const DEFAULT_TIMEZONE = 'Asia/Makassar';
const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

const PairAndWickActions = [
  { id: StartStopSideTaskAction.Short, name: 'Short' },
  { id: StartStopSideTaskAction.Long, name: 'Long' },
  { id: StartStopSideTaskAction.Both, name: 'Both' },
];

const ListingOptions = [
  { id: ExchangesDiffsConditionListing.Listed, name: 'Listed' },
  { id: ExchangesDiffsConditionListing.NotListed, name: 'Not Listed' },
];

const WebhookAuthOptions = [
  { id: ScheduleWebhookAuthType.None, name: 'None' },
  { id: ScheduleWebhookAuthType.APIKey, name: 'API Key' },
];

const ScheduledTaskActionTypes = [
  { id: TaskActionType.NotifyTerminal, name: 'Notify a message on Terminal frontend' },
  { id: TaskActionType.SendWebHook, name: 'Send a message to a webhook' },
  { id: TaskActionType.StartPair, name: 'Start pair' },
  { id: TaskActionType.StopPair, name: 'Stop pair' },
  { id: TaskActionType.StartWick, name: 'Start wick catcher' },
  { id: TaskActionType.StopWick, name: 'Stop wick catcher' },
  { id: TaskActionType.ResumeAgainstExchangesDiffs, name: 'Resume against exchanges diffs' },
  { id: TaskActionType.PauseAgainstExchangesDiffs, name: 'Pause against exchanges diffs' },
];

const DEFAULT_TASK_ACTIONS: {
  [K in TaskActionType]: {
    type: K;
    params: TaskActionParamsMap[K];
    result: { [key: string]: any };
  };
} = {
  [TaskActionType.NotifyTerminal]: {
    type: TaskActionType.NotifyTerminal,
    params: { message: '' },
    result: {},
  },
  [TaskActionType.SendWebHook]: {
    type: TaskActionType.SendWebHook,
    params: { webhook_url: '' },
    result: {},
  },
  [TaskActionType.StartPair]: {
    type: TaskActionType.StartPair,
    params: { ids: '', side: StartStopSideTaskAction.Short },
    result: {},
  },
  [TaskActionType.StopPair]: {
    type: TaskActionType.StopPair,
    params: { ids: '', side: StartStopSideTaskAction.Short },
    result: {},
  },
  [TaskActionType.StartWick]: {
    type: TaskActionType.StartWick,
    params: { ids: '', side: StartStopSideTaskAction.Short },
    result: {},
  },
  [TaskActionType.StopWick]: {
    type: TaskActionType.StopWick,
    params: { ids: '', side: StartStopSideTaskAction.Short },
    result: {},
  },
  [TaskActionType.ResumeAgainstExchangesDiffs]: {
    type: TaskActionType.ResumeAgainstExchangesDiffs,
    params: {
      pause_schedule_id: '',
      market_availability_rules: [],
      trading_pair_ids: '',
      wick_catcher_ids: '',
      exceptional_currencies: '',
      webhook_urls: '',
      webhooks: [],
      side: StartStopSideTaskAction.Short,
    },
    result: {},
  },
  [TaskActionType.PauseAgainstExchangesDiffs]: {
    type: TaskActionType.PauseAgainstExchangesDiffs,
    params: {
      market_availability_rules: [],
      trading_pair_ids: '',
      wick_catcher_ids: '',
      exceptional_currencies: '',
      webhook_urls: '',
      webhooks: [],
      side: StartStopSideTaskAction.Short,
    },
    result: {},
  },
};

const DEFAULT_INSTRUMENTS_FILTER: MarketAvailabilityRule = {
  listing: ExchangesDiffsConditionListing.Listed,
  quote_currency: '',
  main_exchange: '',
};

const PROMPT_EXAMPLES = [
  '- Run at 08:00 AM on weekdays',
  '- Fire at midnight every Saturday',
  '- Trigger every 5 seconds',
  '- At 07:00PM on the second Monday of a month',
  '- At midnight on Christmas Eve',
];

const WEBHOOK_URLS_EXAMPLES = ['https://shohei.v1-terminal.example/pause_webhook', 'https://kyoto.v1-terminal.example/pause_webhook'];

function isValidFormat(dateString: string) {
  return moment(dateString, DATETIME_FORMAT, true).isValid();
}

export function formatNextExecutionDateTime(dateTimeStr: string, targetTimezone: string): string {
  return moment.tz(dateTimeStr, targetTimezone).format('YYYY/MM/DD ddd HH:mm:ss');
}

function buildPromptForOneTimeSchedule(m: moment.Moment) {
  return `Run yearly at ${m.format('HH:mm:ss')} on month of ${m.month() + 1} and day of ${m.date()} `;
}

export interface ScheduleFormState {
  id: number | undefined;
  status: ScheduleStatus;
  name: string;
  occurrence: ScheduleOccurrence;
  run_once_at: string;
  run_at_prompt: string;
  cron_expression: string;
  timezone: string;
  actions: TaskAction[];
}

interface Props {
  item?: Schedule | null;
  onCreate?: (data: any) => void;
  onUpdate?: (id: number, data: any) => void;
  onActivate?: (id: number) => void;
  onDeactivate?: (id: number) => void;
}

export function ScheduleForm({ item, onCreate, onUpdate, onActivate, onDeactivate }: Props) {
  const supportedExchanges = useContext(ExchangesInfoContext);

  const initForm: ScheduleFormState = useMemo(() => {
    if (item) {
      return {
        id: item.id,
        status: item.status,
        name: item.name,
        occurrence: item.occurrence,
        run_once_at: item.run_once_at,
        run_at_prompt: item.run_at_prompt,
        cron_expression: item.cron_expression,
        timezone: item.timezone,
        actions: item.actions,
      };
    }

    return {
      id: undefined,
      status: ScheduleStatus.Deactivated,
      name: '',
      occurrence: ScheduleOccurrence.Recurring,
      run_once_at: '',
      run_at_prompt: '',
      cron_expression: '',
      timezone: DEFAULT_TIMEZONE,
      actions: [],
    };
  }, [item]);

  const [formState, setFormState] = useState(initForm);
  const [showRunOnceAtDateTimePicker, setShowRunOnceAtDateTimePicker] = useState(false);
  const [isGeneratingCronExpression, setIsGeneratingCronExpression] = useState(false);
  const [promptBeforeFocus, setPromptBeforeFocus] = useState(initForm.run_at_prompt);
  const [allowManuallyEditCronExpression, setAllowManuallyEditCronExpression] = useState(false);
  const [selectingDateTime, setSelectingDateTime] = useState(new Date());
  const [projectedUpcomingRuns, setProjectedUpcomingRuns] = useState<string[]>([]);
  const [showMoreProjectedUpcomingRuns, setShowMoreProjectedUpcomingRuns] = useState(false);
  const [showSymbolsDiffsDialog, setShowSymbolsDiffsDialog] = useState(false);
  const [webhookSamplePayload, setWehookSamplePayload] = useState<any>();
  const [webhookSamplePayloadForResumeAgainExchangesDiffs, setWehookSamplePayloadForResumeAgainstExchangesDiffs] = useState<any>();
  const [webhookSamplePayloadForPauseAgainExchangesDiffs, setWehookSamplePayloadForPauseStopAgainstExchangesDiffs] = useState<any>();
  const [sendingWebhookTest, setSendingWebhookTest] = useState(false);

  const handleInputChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeTaskActionType = (index: number) => {
    return (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = event.currentTarget;

      setFormState((prevState) => {
        let currentActions = prevState['actions'] as TaskAction[];
        let defaultAction = DEFAULT_TASK_ACTIONS[value as TaskActionType];
        currentActions[index] = defaultAction;

        return {
          ...prevState,
          actions: currentActions,
        };
      });
    };
  };

  const handleTaskActionParamsValueChange = (index: number) => {
    return (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.currentTarget;

      setFormState((prevState) => {
        let currentActions = prevState['actions'] as TaskAction[];
        let currentAction = currentActions[index];
        let currentActionParams = currentAction.params;
        let newParams = { ...currentActionParams, [name]: value };
        let newActionParams = { ...currentAction, params: newParams } as TaskAction;
        currentActions[index] = newActionParams;

        return {
          ...prevState,
          actions: currentActions,
        };
      });
    };
  };

  const handleTaskActionWebhookValueChange = (actionIndex: number, webhookIndex: number) => {
    return (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
      const { name, value } = event.currentTarget;

      setFormState((prevState) => {
        let currentActions = prevState['actions'] as TaskAction[];
        let currentAction = currentActions[actionIndex];

        if (
          currentAction.type !== TaskActionType.PauseAgainstExchangesDiffs &&
          currentAction.type !== TaskActionType.ResumeAgainstExchangesDiffs
        ) {
          return prevState;
        }

        let currentActionParams = currentAction.params;
        let webhooks = currentActionParams.webhooks || [];
        let currentWebhook = webhooks[webhookIndex];

        let newWebhookParams = { ...currentWebhook, [name]: value };
        webhooks[webhookIndex] = newWebhookParams;

        let newParams = { ...currentActionParams, webhooks: webhooks };
        let newActionParams = { ...currentAction, params: newParams } as TaskAction;
        currentActions[actionIndex] = newActionParams;

        return {
          ...prevState,
          actions: currentActions,
        };
      });
    };
  };

  const handleAddNewWebhookForTaskAction = (actionIndex: number) => {
    setFormState((prevState) => {
      let currentActions = prevState['actions'] as TaskAction[];
      let currentAction = currentActions[actionIndex];

      if (
        currentAction.type !== TaskActionType.PauseAgainstExchangesDiffs &&
        currentAction.type !== TaskActionType.ResumeAgainstExchangesDiffs
      ) {
        return prevState;
      }

      let currentActionParams = currentAction.params;
      let webhooks = currentActionParams.webhooks || [];

      let newWebhook: ScheduleWebhook = {
        url: '',
        auth_type: ScheduleWebhookAuthType.None,
      };

      webhooks = [...webhooks, newWebhook];

      let newParams = { ...currentActionParams, webhooks: webhooks };
      let newActionParams = { ...currentAction, params: newParams } as TaskAction;
      currentActions[actionIndex] = newActionParams;

      return {
        ...prevState,
        actions: currentActions,
      };
    });
  };

  const handleDeleteWebhookForTaskAction = (actionIndex: number, webhookIndex: number) => {
    setFormState((prevState) => {
      let currentActions = prevState['actions'] as TaskAction[];
      let currentAction = currentActions[actionIndex];

      if (
        currentAction.type !== TaskActionType.PauseAgainstExchangesDiffs &&
        currentAction.type !== TaskActionType.ResumeAgainstExchangesDiffs
      ) {
        return prevState;
      }

      let currentActionParams = currentAction.params;
      let webhooks = currentActionParams.webhooks || [];

      webhooks.splice(webhookIndex, 1);

      let newParams = { ...currentActionParams, webhooks: webhooks };
      let newActionParams = { ...currentAction, params: newParams } as TaskAction;
      currentActions[actionIndex] = newActionParams;

      return {
        ...prevState,
        actions: currentActions,
      };
    });
  };

  const handleAddNewInstrumentsFilter = (index: number) => {
    setFormState((prevState) => {
      let currentActions = prevState['actions'] as TaskAction[];
      let currentAction = currentActions[index];

      if (
        currentAction.type !== TaskActionType.ResumeAgainstExchangesDiffs &&
        currentAction.type !== TaskActionType.PauseAgainstExchangesDiffs
      )
        return prevState;

      let currentActionParams = currentAction.params;
      let currentInstrumentsFilters = currentActionParams.market_availability_rules;
      let updatedActionParams = {
        ...currentActionParams,
        market_availability_rules: [...currentInstrumentsFilters, DEFAULT_INSTRUMENTS_FILTER],
      };

      let updatedAction = { ...currentAction, params: updatedActionParams } as TaskAction;
      currentActions[index] = updatedAction;

      return {
        ...prevState,
        actions: currentActions,
      };
    });
  };

  const handleInstrumentsFilterChangeValueChange = (action_index: number, filter_index: number) => {
    return (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
      let { name, value } = event.currentTarget;

      if (name === 'quote_currency') {
        value = _.toUpper(value);
      }

      setFormState((prevState) => {
        let currentActions = prevState['actions'] as TaskAction[];
        let currentAction = currentActions[action_index];

        if (
          currentAction.type !== TaskActionType.ResumeAgainstExchangesDiffs &&
          currentAction.type !== TaskActionType.PauseAgainstExchangesDiffs
        )
          return prevState;

        let currentActionParams = currentAction.params;
        let currentFilters = currentActionParams.market_availability_rules;
        let currentFilter = currentFilters[filter_index];

        let updatedFilter = { ...currentFilter, [name]: value };
        currentFilters[filter_index] = updatedFilter;

        let updatedActionParams = { ...currentActionParams, market_availability_rules: currentFilters };
        currentActions[action_index] = { ...currentAction, params: updatedActionParams };

        return {
          ...prevState,
          actions: currentActions,
        };
      });
    };
  };

  const handleDeleteInstrumentFilter = (action_index: number, filter_index: number) => {
    setFormState((prevState) => {
      let currentActions = prevState['actions'] as TaskAction[];
      let currentAction = currentActions[action_index];

      if (
        currentAction.type !== TaskActionType.ResumeAgainstExchangesDiffs &&
        currentAction.type !== TaskActionType.PauseAgainstExchangesDiffs
      )
        return prevState;

      let currentActionParams = currentAction.params;
      let currentFilters = currentActionParams.market_availability_rules;
      currentFilters.splice(filter_index, 1);

      let updatedActionParams = { ...currentActionParams, market_availability_rules: currentFilters };
      currentActions[action_index] = { ...currentAction, params: updatedActionParams };

      return {
        ...prevState,
        actions: currentActions,
      };
    });
  };

  const handleAddNewAction = (fieldName: keyof ScheduleFormState) => {
    setFormState((prevState) => {
      let fieldData = prevState[fieldName] as TaskAction[];
      let newTaskAction = DEFAULT_TASK_ACTIONS[TaskActionType.NotifyTerminal];

      return {
        ...prevState,
        [fieldName]: [...fieldData, ...[newTaskAction]],
      };
    });
  };

  const handleDeleteAction = (fieldName: keyof ScheduleFormState, index: number) => {
    setFormState((prevState) => {
      let fieldData = prevState[fieldName] as TaskAction[];
      fieldData.splice(index, 1);

      return {
        ...prevState,
        [fieldName]: fieldData,
      };
    });
  };

  const handleRunOnceAtChange = (event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      run_once_at: value,
    }));

    let m = moment(value, DATETIME_FORMAT, true);

    debounceGenerateCronExpression(buildPromptForOneTimeSchedule(m));
  };

  const debounceGenerateCronExpression = useCallback(
    _.debounce((prompt: string) => {
      handleGenerateCronExpression(prompt);
    }, 500),
    [],
  );

  const handleGenerateCronExpression = useCallback(
    (prompt: string) => {
      setIsGeneratingCronExpression(true);
      setAllowManuallyEditCronExpression(false);

      axios
        .post('/api/scheduled_tasks/generate_cron_expression', { prompt })
        .then(({ data }) => {
          const { error, result } = data;

          if (result === '' && error !== '') {
            AppToaster.show({ message: error, icon: 'warning-sign', intent: Intent.DANGER, timeout: 15000 });
          }

          setFormState((prevState) => ({
            ...prevState,
            cron_expression: result,
          }));
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 15000 });
        })
        .finally(() => setIsGeneratingCronExpression(false));
    },
    [item],
  );

  const handleSendTestWebhookConnection = useCallback(async (webhooks: ScheduleWebhook[], payload: any) => {
    setSendingWebhookTest(true);

    try {
      const results = await Promise.all(
        webhooks.map(async (webhook) => {
          try {
            const response = await axios.post('/api/scheduled_tasks/send_webhook_connection_test', {
              webhook: webhook,
              payload: payload,
            });
            return { url: webhook, success: true, data: response.data };
          } catch (error: any) {
            return {
              url: webhook.url,
              success: false,
              error: error.response?.data || 'Request failed',
            };
          }
        }),
      );

      const successfulRequests = results.filter((result) => result.success);
      const failedRequests = results.filter((result) => !result.success);

      if (successfulRequests.length > 0) {
        AppToaster.show({
          message: `Successfully sent webhook test to ${successfulRequests.length} URL(s)`,
          icon: 'warning-sign',
          intent: Intent.SUCCESS,
          timeout: 15000,
        });
      }

      if (failedRequests.length > 0) {
        failedRequests.forEach((failed) => {
          AppToaster.show({
            message: `Failed to send webhook test to ${failed.url}: ${JSON.stringify(failed.error)}`,
            icon: 'warning-sign',
            intent: Intent.DANGER,
            timeout: 15000,
          });
        });
      }
    } catch (error) {
      AppToaster.show({
        message: 'Failed to process webhook tests',
        icon: 'warning-sign',
        intent: Intent.DANGER,
        timeout: 15000,
      });
    } finally {
      setSendingWebhookTest(false);
    }
  }, []);

  const handleUpdateDateTime = useCallback(() => {
    let chosenDateTime = selectingDateTime ? moment(selectingDateTime).format(DATETIME_FORMAT) : '';

    setFormState((prevState) => ({
      ...prevState,
      run_once_at: chosenDateTime,
    }));

    setShowRunOnceAtDateTimePicker(false);
    debounceGenerateCronExpression(buildPromptForOneTimeSchedule(moment(chosenDateTime)));
  }, [selectingDateTime, debounceGenerateCronExpression]);

  const handleSwitchToManualEditCronExpression = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      run_at_prompt: '',
    }));

    setAllowManuallyEditCronExpression(true);
  }, []);

  const handleCreate = () => {
    onCreate && onCreate(formState);
  };

  const handleUpdate = () => {
    if (!item) {
      return;
    }

    onUpdate && onUpdate(item.id, formState);
  };

  const handleActivate = () => {
    if (!item) {
      return;
    }

    onActivate && onActivate(item.id);
  };

  const handleDeactivate = () => {
    if (!item) {
      return;
    }

    onDeactivate && onDeactivate(item.id);
  };

  useEffect(() => {
    setFormState(initForm);
  }, [initForm]);

  useEffect(() => {
    if (formState.occurrence === ScheduleOccurrence.OneTime) {
      return setFormState((prevState) => ({
        ...prevState,
        run_at_prompt: '',
      }));
    }

    if (formState.occurrence === ScheduleOccurrence.Recurring) {
      return setFormState((prevState) => ({
        ...prevState,
        run_once_at: '',
      }));
    }
  }, [formState.occurrence]);

  useEffect(() => {
    if (formState.cron_expression === '') {
      setProjectedUpcomingRuns([]);
    } else {
      let params = {
        cron_expression: formState.cron_expression,
        next_runs_number: 5,
        timezone: formState.timezone,
      };

      axios
        .post('/api/scheduled_tasks/projected_upcoming_runs', params)
        .then(({ data }) => {
          const { result } = data;
          setProjectedUpcomingRuns(result);
        })
        .catch((error) => {
          setProjectedUpcomingRuns([]);
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 15000 });
        })
        .finally(() => setIsGeneratingCronExpression(false));
    }
  }, [formState.cron_expression]);

  useEffect(() => {
    setWehookSamplePayload({
      id: formState.id || 0,
      name: formState.name,
      occurrence: formState.occurrence,
      cron_expression: formState.cron_expression,
      timezone: formState.timezone,
      triggered_at: projectedUpcomingRuns[0],
      upcoming_run: projectedUpcomingRuns[1],
    });
  }, [formState, projectedUpcomingRuns]);

  useEffect(() => {
    setWehookSamplePayloadForResumeAgainstExchangesDiffs({
      schedule: {
        id: formState.id || 0,
        name: formState.name,
        occurrence: formState.occurrence,
        cron_expression: formState.cron_expression,
        timezone: formState.timezone,
        triggered_at: projectedUpcomingRuns[0],
        upcoming_run: projectedUpcomingRuns[1],
        action: {
          type: 'resume_against_exchanges_diffs',
          coins_diffs: ['PEPE', 'ONDO'],
          pair_ids: [12, 331, 42],
          side: 'short',
        },
        task_activation_id: 0,
      },
    });
  }, [formState]);

  useEffect(() => {
    setWehookSamplePayloadForPauseStopAgainstExchangesDiffs({
      schedule: {
        id: formState.id || 0,
        name: formState.name,
        occurrence: formState.occurrence,
        cron_expression: formState.cron_expression,
        timezone: formState.timezone,
        triggered_at: projectedUpcomingRuns[0],
        upcoming_run: projectedUpcomingRuns[1],
        action: {
          type: 'pause_against_exchanges_diffs',
          coins_diffs: ['PEPE', 'ONDO'],
          pair_ids: [23, 123, 12],
          side: 'long',
        },
        task_activation_id: 0,
      },
    });
  }, [formState]);

  return (
    <>
      <FormGroup label="Name" labelFor="name" labelInfo="(required)">
        <InputGroup id="name" name="name" value={formState.name} onChange={handleInputChange} placeholder="" />
      </FormGroup>

      <FormGroup label="" labelFor="occurrence" labelInfo="(required)" helperText="">
        <RadioGroup name="occurrence" label="Occurrence" onChange={handleInputChange} selectedValue={formState.occurrence}>
          <Radio label="One-time schedule" value={ScheduleOccurrence.OneTime} />
          <Radio label="Recurring schedule" value={ScheduleOccurrence.Recurring} />
        </RadioGroup>
      </FormGroup>

      <H4>1. When</H4>

      <div className="grid grid-cols-3 gap-3 mt-3">
        <div className="col-span-2">
          {formState.occurrence === ScheduleOccurrence.OneTime && (
            <FormGroup
              label="Date and time"
              labelFor="occurrence"
              labelInfo="(required)"
              helperText="YYYY/MM/DD HH:MM format using a 24-hour clock"
            >
              <ControlGroup fill={false} vertical={false}>
                <Popover2
                  isOpen={showRunOnceAtDateTimePicker}
                  onClose={() => setShowRunOnceAtDateTimePicker(false)}
                  popoverClassName={PopoverClasses.POPOVER2}
                  enforceFocus={false}
                  content={
                    <DatePicker
                      className={Classes.ELEVATION_1}
                      value={selectingDateTime}
                      onChange={setSelectingDateTime}
                      minDate={new Date()}
                      timePickerProps={{ precision: TimePrecision.SECOND }}
                      showActionsBar={true}
                      footerElement={<Button onClick={handleUpdateDateTime}>Update</Button>}
                    />
                  }
                  minimal={false}
                  position={Position.BOTTOM}
                >
                  <Button icon="calendar" onClick={() => setShowRunOnceAtDateTimePicker(!showRunOnceAtDateTimePicker)}></Button>
                </Popover2>

                <InputGroup
                  id="run_once_at"
                  name="run_once_at"
                  fill={true}
                  value={formState.run_once_at}
                  onChange={handleRunOnceAtChange}
                  placeholder={DATETIME_FORMAT}
                />
              </ControlGroup>
            </FormGroup>
          )}

          {formState.occurrence === ScheduleOccurrence.Recurring && (
            <FormGroup label="Use human language to describe when this schedule executes" labelFor="run_at_prompt">
              <TextArea
                id="run_at_prompt"
                name="run_at_prompt"
                fill={true}
                value={formState.run_at_prompt}
                onChange={handleInputChange}
                placeholder={`Examples:\n` + PROMPT_EXAMPLES.join('\n')}
                onFocus={() => setPromptBeforeFocus(formState.run_at_prompt)}
                onBlur={() => {
                  const isChanged = formState.run_at_prompt !== promptBeforeFocus;

                  if (formState.run_at_prompt !== '' && isChanged) {
                    handleGenerateCronExpression(formState.run_at_prompt);
                  }
                }}
                growVertically={true}
              />
            </FormGroup>
          )}
        </div>

        <FormGroup label="Timezone" labelFor="occurrence" labelInfo="(required)" helperText="">
          <TimezoneSelect
            selectedItem={formState.timezone}
            handleSelect={(tz: string) =>
              setFormState((prevState) => ({
                ...prevState,
                timezone: tz,
              }))
            }
          />
        </FormGroup>
      </div>

      <div className="grid grid-cols-3 gap-3 mt-3">
        <div className="col-span-2">
          <FormGroup
            label="Cron Expression"
            labelFor="cron_expression"
            labelInfo={
              isGeneratingCronExpression ? (
                <span className="italic text-xs">Regenerating cron expression using AI based on input...</span>
              ) : (
                <span className="text-xs italic">
                  (
                  <a href="https://hexdocs.pm/quantum/crontab-format.html#basics" target="_blank" rel="noreferrer" className="text-black">
                    Learn the format
                  </a>
                  )
                </span>
              )
            }
          >
            <InputGroup
              id="cron_expression"
              name="cron_expression"
              value={formState.cron_expression}
              onChange={handleInputChange}
              placeholder=""
              disabled={
                isGeneratingCronExpression || formState.occurrence === ScheduleOccurrence.OneTime || !allowManuallyEditCronExpression
              }
            />

            {formState.occurrence === ScheduleOccurrence.Recurring && (
              <p className="mt-1 text-xxs">
                This phrase is typically produced automatically.{' '}
                {formState.occurrence === ScheduleOccurrence.Recurring && (
                  <span onClick={() => handleSwitchToManualEditCronExpression()} className="underline hover:no-underline cursor-pointer">
                    Click here if you wish to edit it manually
                  </span>
                )}
              </p>
            )}
          </FormGroup>

          {formState.cron_expression !== '' && (
            <>
              <H6>5 Projected Upcoming Runs</H6>

              <Pre className="whitespace-pre-wrap">
                {projectedUpcomingRuns
                  .map((dateTime, index) => {
                    return `${index + 1}. ` + formatNextExecutionDateTime(dateTime, formState.timezone);
                  })
                  .join('\n')}
              </Pre>

              <p className="mt-1 text-xxs">
                {formState.occurrence === ScheduleOccurrence.Recurring && (
                  <span onClick={() => setShowMoreProjectedUpcomingRuns(true)} className="underline hover:no-underline cursor-pointer">
                    Show more
                  </span>
                )}
              </p>
              <Dialog
                portalContainer={document.getElementById('app') || undefined}
                title={`Projected upcoming runs`}
                transitionDuration={500}
                isOpen={showMoreProjectedUpcomingRuns}
                onClose={() => {
                  setShowMoreProjectedUpcomingRuns(false);
                }}
              >
                <div className={Classes.DIALOG_BODY}>
                  <ProjectedUpcomingRuns
                    default_items={projectedUpcomingRuns}
                    cron_expression={formState.cron_expression}
                    timezone={formState.timezone}
                  />
                </div>

                <div className={Classes.DIALOG_FOOTER}></div>
              </Dialog>
            </>
          )}
        </div>

        <div></div>
      </div>

      <div className="mt-2">
        <H4>2. Then do these</H4>

        {formState.actions.map((action, action_index) => {
          return (
            <div key={action_index} className="border border-gray-200 rounded-md py-2 px-3 mb-4 group">
              <h6 className="text-sm font-light my-1 relative">
                <Tag minimal={false} intent={Intent.WARNING}>
                  Action {action_index + 1}
                </Tag>

                <span
                  onClick={() => handleDeleteAction('actions', action_index)}
                  className="absolute top-0 right-0 p-0 text-gray-400 hover:text-gray-500 hidden group-hover:block cursor-pointer"
                >
                  <Icon icon="cross"></Icon>
                </span>
              </h6>

              <div className="grid grid-cols-11 gap-3 mt-1">
                <div className="col-span-3">
                  <FormGroup label="Action Type" labelFor={'type-' + action_index} labelInfo="(required)">
                    <ControlGroup>
                      <HTMLSelect
                        id={'type-' + action_index}
                        name="type"
                        value={action.type}
                        onChange={handleChangeTaskActionType(action_index)}
                      >
                        <option>Choose an action type...</option>
                        {ScheduledTaskActionTypes.map(({ id, name }) => (
                          <option value={id} key={id}>
                            {name}
                          </option>
                        ))}
                      </HTMLSelect>
                    </ControlGroup>
                  </FormGroup>
                </div>

                <div></div>

                <div className="col-span-3">
                  {action.type === TaskActionType.ResumeAgainstExchangesDiffs && (
                    <FormGroup
                      label="Linked Pause Schedule ID"
                      labelFor={'pause-schedule-id-' + action_index}
                      helperText="For other apps like Terminal V1 could track previous pauses"
                      className="col-span-1"
                    >
                      <InputGroup
                        id={'pause-schedule-id-' + action_index}
                        name="pause_schedule_id"
                        fill={true}
                        value={action.params.pause_schedule_id}
                        onChange={handleTaskActionParamsValueChange(action_index)}
                        placeholder="E.g. 5"
                      />
                    </FormGroup>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-3 mt-3">
                {action.type === TaskActionType.NotifyTerminal && (
                  <FormGroup label="Message" labelFor={'message-' + action_index}>
                    <TextArea
                      id={'message-' + action_index}
                      name="message"
                      fill={true}
                      value={action.params.message}
                      onChange={handleTaskActionParamsValueChange(action_index)}
                      placeholder="Type in a message you want to show on Terminal frontend"
                    />
                  </FormGroup>
                )}

                {action.type === TaskActionType.SendWebHook && (
                  <FormGroup
                    label="Webhook URL"
                    labelFor={'message-' + action_index}
                    labelInfo={
                      <span className="text-xs italic">
                        (
                        <a href="https://webhook.site/" target="_blank" rel="noreferrer" className="text-black">
                          Get an Webhook URL for testing purpose here
                        </a>
                        )
                      </span>
                    }
                  >
                    <InputGroup
                      id="webhook_url"
                      name="webhook_url"
                      value={action.params.webhook_url}
                      onChange={handleTaskActionParamsValueChange(action_index)}
                    />

                    <p className="mt-5">Sample Payload</p>
                    <Pre className="whitespace-pre-wrap">{JSON.stringify(webhookSamplePayload, null, '\t')}</Pre>

                    <Button
                      loading={sendingWebhookTest}
                      intent={Intent.NONE}
                      onClick={() =>
                        handleSendTestWebhookConnection(
                          [{ url: action.params.webhook_url, auth_type: ScheduleWebhookAuthType.None }],
                          webhookSamplePayload,
                        )
                      }
                      disabled={isGeneratingCronExpression}
                    >
                      Test Connection
                    </Button>
                  </FormGroup>
                )}

                {(action.type === TaskActionType.StartPair || action.type === TaskActionType.StopPair) && (
                  <div className="grid grid-cols-3 gap-3 mt-3">
                    <FormGroup
                      label="IDs"
                      labelFor={'pair-ids-' + action_index}
                      helperText="List of ids delimited by commas."
                      className="col-span-2"
                    >
                      <InputGroup
                        id={'pair-ids-' + action_index}
                        name="ids"
                        fill={true}
                        value={action.params.ids}
                        onChange={handleTaskActionParamsValueChange(action_index)}
                        placeholder="E.g. 2, 5, 10"
                      />
                    </FormGroup>

                    <FormGroup label="Side" labelFor={'side-' + action_index} helperText="">
                      <ControlGroup fill={true}>
                        <HTMLSelect
                          id={'side-' + action_index}
                          name="side"
                          value={action.params.side}
                          onChange={handleTaskActionParamsValueChange(action_index)}
                        >
                          <option>Choose an action type...</option>
                          {PairAndWickActions.map(({ id, name }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ))}
                        </HTMLSelect>
                      </ControlGroup>
                    </FormGroup>
                  </div>
                )}

                {(action.type === TaskActionType.StartWick || action.type === TaskActionType.StopWick) && (
                  <div className="grid grid-cols-3 gap-3 mt-3">
                    <FormGroup
                      label="IDs"
                      labelFor={'wick-catcher-ids-' + action_index}
                      helperText="List of ids delimited by commas."
                      className="col-span-2"
                    >
                      <InputGroup
                        id={'wick-catcher-ids-' + action_index}
                        name="ids"
                        fill={true}
                        value={action.params.ids}
                        onChange={handleTaskActionParamsValueChange(action_index)}
                        placeholder="E.g. 2, 5, 10"
                      />
                    </FormGroup>

                    <FormGroup label="Side" labelFor={'side-' + action_index} helperText="">
                      <ControlGroup fill={true}>
                        <HTMLSelect
                          id={'side-' + action_index}
                          name="side"
                          value={action.params.side}
                          onChange={handleTaskActionParamsValueChange(action_index)}
                        >
                          <option>Choose an action type...</option>
                          {PairAndWickActions.map(({ id, name }) => (
                            <option value={id} key={id}>
                              {name}
                            </option>
                          ))}
                        </HTMLSelect>
                      </ControlGroup>
                    </FormGroup>
                  </div>
                )}

                {(action.type === TaskActionType.ResumeAgainstExchangesDiffs ||
                  action.type === TaskActionType.PauseAgainstExchangesDiffs) && (
                  <div>
                    {action.type === TaskActionType.PauseAgainstExchangesDiffs && (
                      <div className="cross-exchange-market-filters">
                        <p>Cross-Exchange Market Filter</p>

                        <div className="grid grid-cols-1 gap-3 mt-3">
                          {action.params.market_availability_rules.map((filter, filter_index) => {
                            return (
                              <div
                                key={filter_index}
                                className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 cursor-pointer relative"
                              >
                                <div className="">
                                  <h6 className="text-sm font-medium my-1 relative">
                                    <span>Filter {filter_index + 1}</span>

                                    <span
                                      onClick={() => handleDeleteInstrumentFilter(action_index, filter_index)}
                                      className="absolute top-0 right-0 p-0 text-gray-400 hover:text-gray-500 hidden group-hover:block cursor-pointer"
                                    >
                                      <Icon icon="cross"></Icon>
                                    </span>
                                  </h6>

                                  <div className="grid grid-cols-11 gap-3 mt-2">
                                    <div className="col-span-3">
                                      <FormGroup label="Availability" labelFor={`listing-${action_index}-${filter_index}`} helperText="">
                                        <ControlGroup fill={true}>
                                          <HTMLSelect
                                            id={`listing-${action_index}-${filter_index}`}
                                            name="listing"
                                            value={action.params.market_availability_rules[filter_index].listing}
                                            onChange={handleInstrumentsFilterChangeValueChange(action_index, filter_index)}
                                          >
                                            <option>Choose an listing option...</option>
                                            {ListingOptions.map(({ id, name }) => (
                                              <option value={id} key={id}>
                                                {name}
                                              </option>
                                            ))}
                                          </HTMLSelect>
                                        </ControlGroup>
                                      </FormGroup>
                                    </div>

                                    <div className="flex items-center justify-center">
                                      <p>WITH</p>
                                    </div>

                                    <div className="col-span-3">
                                      <FormGroup label="Quote Currency" labelFor={`quote_currency-${action_index}-${filter_index}`}>
                                        <InputGroup
                                          id={`quote_currency-${action_index}-${filter_index}`}
                                          name="quote_currency"
                                          value={action.params.market_availability_rules[filter_index].quote_currency}
                                          onChange={handleInstrumentsFilterChangeValueChange(action_index, filter_index)}
                                          placeholder="E.g. USD"
                                        />
                                      </FormGroup>
                                    </div>

                                    <div className="flex items-center justify-center">
                                      <p>ON</p>
                                    </div>

                                    <div className="col-span-3">
                                      <FormGroup label="Exchange" labelFor={`exchange-${action_index}-${filter_index}`} helperText="">
                                        <ControlGroup fill={true}>
                                          <HTMLSelect
                                            id={`main_exchange-${action_index}-${filter_index}`}
                                            name="main_exchange"
                                            value={action.params.market_availability_rules[filter_index].main_exchange}
                                            onChange={handleInstrumentsFilterChangeValueChange(action_index, filter_index)}
                                          >
                                            <option>Choose an exchange...</option>
                                            {supportedExchanges.map(({ id, name }) => (
                                              <option value={id} key={id}>
                                                {name}
                                              </option>
                                            ))}
                                          </HTMLSelect>
                                        </ControlGroup>
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <div className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 text-center cursor-pointer relative">
                            <div className="invisible">
                              <h6 className="text-sm font-medium my-1 relative">Action</h6>

                              <div className="grid grid-cols-3 gap-3">
                                <FormGroup label="Trigger threshold" labelInfo="" helperText="">
                                  <NumericInput
                                    name="trigger_threshold"
                                    fill={true}
                                    disabled={true}
                                    buttonPosition="none"
                                    placeholder="E.g. 5"
                                  />
                                </FormGroup>

                                <FormGroup label="Short multiplier" labelInfo="" helperText="">
                                  <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                                </FormGroup>

                                <FormGroup label="Long multiplier" labelInfo="" helperText="">
                                  <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
                                </FormGroup>
                              </div>
                            </div>

                            <div
                              onClick={() => handleAddNewInstrumentsFilter(action_index)}
                              className="absolute w-full h-full top-0 left-0 flex justify-center items-center"
                            >
                              <span className="block">Add new filter</span>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-11 gap-3 mt-7">
                          <div className="col-span-3">
                            <FormGroup
                              label="Exceptional Currencies"
                              labelFor={'exceptional_currencies-' + action_index}
                              helperText="List of currencies, delimited by commas"
                            >
                              <InputGroup
                                id={'exceptional_currencies-' + action_index}
                                name="exceptional_currencies"
                                fill={true}
                                value={action.params.exceptional_currencies}
                                onChange={handleTaskActionParamsValueChange(action_index)}
                                placeholder="E.g. BNB, ETH"
                              />
                            </FormGroup>
                          </div>

                          <div></div>

                          <div className="col-span-3">
                            <FormGroup label="Side" labelFor={'side-' + action_index} helperText="">
                              <ControlGroup fill={true}>
                                <HTMLSelect
                                  id={'side-' + action_index}
                                  name="side"
                                  value={action.params.side}
                                  onChange={handleTaskActionParamsValueChange(action_index)}
                                >
                                  <option>Choose an action type...</option>
                                  {PairAndWickActions.map(({ id, name }) => (
                                    <option value={id} key={id}>
                                      {name}
                                    </option>
                                  ))}
                                </HTMLSelect>
                              </ControlGroup>
                            </FormGroup>
                          </div>

                          <div></div>

                          <div className="col-span-3"></div>
                        </div>

                        <div>
                          <Dialog
                            portalContainer={document.getElementById('app') || undefined}
                            title={`Symbols differences between exchanges`}
                            transitionDuration={500}
                            isOpen={showSymbolsDiffsDialog}
                            onClose={() => {
                              setShowSymbolsDiffsDialog(false);
                            }}
                          >
                            <div className={Classes.DIALOG_BODY}>
                              <CoinsDiffsList
                                market_availability_rules={action.params.market_availability_rules}
                                exceptional_currencies={action.params.exceptional_currencies.split(',').map((s) => s.trim())}
                              />
                            </div>

                            <div className={Classes.DIALOG_FOOTER}></div>
                          </Dialog>

                          <Button loading={sendingWebhookTest} intent={Intent.NONE} onClick={() => setShowSymbolsDiffsDialog(true)}>
                            Preview Coins/Tokens Differences
                          </Button>
                        </div>
                      </div>
                    )}

                    <div className="schedule-actions">
                      <div className="mt-5">
                        <H6>Perform actions on this Terminal</H6>
                        <div className="grid grid-cols-11 gap-3 mt-1">
                          <div className="col-span-3">
                            <FormGroup
                              label="Trading Pair IDs"
                              labelFor={'trading-pair-ids-' + action_index}
                              helperText="List of ids, owned by this Terminal, delimited by commas"
                              className="col-span-1"
                            >
                              <InputGroup
                                id={'trading-pair-ids-' + action_index}
                                name="trading_pair_ids"
                                fill={true}
                                value={action.params.trading_pair_ids}
                                onChange={handleTaskActionParamsValueChange(action_index)}
                                placeholder="E.g. 2, 5, 10"
                              />
                            </FormGroup>
                          </div>

                          <div></div>

                          <div className="col-span-3">
                            <FormGroup
                              label="Wick Catcher IDs"
                              labelFor={'wick-catcher-ids-' + action_index}
                              helperText="List of ids, owned by this Terminal, delimited by commas"
                              className="col-span-1"
                            >
                              <InputGroup
                                id={'wick-catcher-ids-' + action_index}
                                name="wick_catcher_ids"
                                fill={true}
                                value={action.params.wick_catcher_ids}
                                onChange={handleTaskActionParamsValueChange(action_index)}
                                placeholder="E.g. 2, 5, 10"
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <p className="text-xs">NOTE: These wick catchers/trading pairs have to be owned by this Terminal.</p>
                      </div>

                      <div className="mt-5">
                        <H6>Notify other Terminals (or apps)</H6>
                        <div className="grid grid-cols-11 gap-3 mt-3">
                          <div className="col-span-7">
                            {action.params.webhooks?.map((webhook, webhookIndex) => {
                              return (
                                <div>
                                  <FormGroup
                                    label={`- Webhook URL ${webhookIndex + 1}`}
                                    labelFor={`webhook-url-${action_index}-${webhookIndex}`}
                                    labelInfo={
                                      <span className="text-xs italic group">
                                        (
                                        <a href="https://webhook.site/" target="_blank" rel="noreferrer" className="text-black">
                                          Get an Webhook URL for testing purpose here
                                        </a>
                                        )
                                        <span
                                          onClick={() => handleDeleteWebhookForTaskAction(action_index, webhookIndex)}
                                          className="float-right p-0 text-gray-400 hover:text-gray-500 hidden group-hover:inline cursor-pointer"
                                        >
                                          <Icon icon="cross"></Icon>
                                        </span>
                                      </span>
                                    }
                                    helperText="List of webhook URLs, one per each line"
                                  >
                                    <InputGroup
                                      id={`webhook-url-${action_index}-${webhookIndex}`}
                                      name="url"
                                      fill={true}
                                      value={webhook.url}
                                      onChange={handleTaskActionWebhookValueChange(action_index, webhookIndex)}
                                      placeholder={`E.g. ${WEBHOOK_URLS_EXAMPLES[webhookIndex % WEBHOOK_URLS_EXAMPLES.length]}`}
                                    />
                                  </FormGroup>

                                  <FormGroup label="Authentication" labelFor={`webhook-auth-type-${action_index}-${webhookIndex}`}>
                                    <ControlGroup>
                                      <HTMLSelect
                                        id={`webhook-auth-type-${action_index}-${webhookIndex}`}
                                        name="auth_type"
                                        value={webhook.auth_type}
                                        onChange={handleTaskActionWebhookValueChange(action_index, webhookIndex)}
                                      >
                                        <option>Choose an listing option...</option>
                                        {WebhookAuthOptions.map(({ id, name }) => (
                                          <option value={id} key={id}>
                                            {name}
                                          </option>
                                        ))}
                                      </HTMLSelect>
                                    </ControlGroup>
                                  </FormGroup>

                                  {webhook.auth_type === ScheduleWebhookAuthType.APIKey && (
                                    <FormGroup label="API key" labelFor={`webhook-api-key-${action_index}-${webhookIndex}`}>
                                      <InputGroup
                                        id={`webhook-api-key-${action_index}-${webhookIndex}`}
                                        name="api_key"
                                        fill={true}
                                        value={webhook.api_key}
                                        onChange={handleTaskActionWebhookValueChange(action_index, webhookIndex)}
                                        // placeholder="E.g. 2, 5, 10"
                                      />
                                    </FormGroup>
                                  )}
                                </div>
                              );
                            })}

                            <div className="mt-7">
                              <Button intent={Intent.NONE} onClick={() => handleAddNewWebhookForTaskAction(action_index)}>
                                Add new webhook
                              </Button>
                            </div>

                            <div className="mt-5">
                              <Divider></Divider>
                            </div>

                            <p className="mt-5">Sample Payload</p>

                            {action.type === TaskActionType.PauseAgainstExchangesDiffs && (
                              <>
                                <Pre className="whitespace-pre-wrap">
                                  {JSON.stringify(webhookSamplePayloadForPauseAgainExchangesDiffs, null, '\t')}
                                </Pre>

                                <Button
                                  loading={sendingWebhookTest}
                                  intent={Intent.NONE}
                                  onClick={() =>
                                    handleSendTestWebhookConnection(action.params.webhooks, webhookSamplePayloadForPauseAgainExchangesDiffs)
                                  }
                                  disabled={isGeneratingCronExpression}
                                >
                                  Test Connection
                                </Button>
                              </>
                            )}

                            {action.type === TaskActionType.ResumeAgainstExchangesDiffs && (
                              <>
                                <Pre className="whitespace-pre-wrap">
                                  {JSON.stringify(webhookSamplePayloadForResumeAgainExchangesDiffs, null, '\t')}
                                </Pre>

                                <Button
                                  loading={sendingWebhookTest}
                                  intent={Intent.NONE}
                                  onClick={() =>
                                    handleSendTestWebhookConnection(
                                      action.params.webhooks,
                                      webhookSamplePayloadForResumeAgainExchangesDiffs,
                                    )
                                  }
                                  disabled={isGeneratingCronExpression}
                                >
                                  Test Connection
                                </Button>
                              </>
                            )}
                          </div>
                        </div>

                        <div></div>

                        <div className="col-span-5"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}

        <div className="rounded-md border hover:border-gray-500 border-gray-400 border-dashed text-gray-400 hover:text-gray-500 p-2 text-center cursor-pointer relative">
          <div className="invisible">
            <h6 className="text-sm font-medium my-1 relative">Action</h6>

            <div className="grid grid-cols-3 gap-3">
              <FormGroup label="Trigger threshold" labelInfo="" helperText="">
                <NumericInput name="trigger_threshold" fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 5" />
              </FormGroup>

              <FormGroup label="Short multiplier" labelInfo="" helperText="">
                <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
              </FormGroup>

              <FormGroup label="Long multiplier" labelInfo="" helperText="">
                <NumericInput fill={true} disabled={true} buttonPosition="none" placeholder="E.g. 2" />
              </FormGroup>
            </div>
          </div>

          <div
            onClick={() => handleAddNewAction('actions')}
            className="absolute w-full h-full top-0 left-0 flex justify-center items-center"
          >
            <span className="block">Add new action</span>
          </div>
        </div>
      </div>

      <div className="mt-5">
        {!item && (
          <p>
            <Button intent={Intent.PRIMARY} onClick={handleCreate} disabled={isGeneratingCronExpression}>
              Submit
            </Button>
          </p>
        )}

        {item && (
          <>
            <div className="mt-3">
              <p>
                <Button
                  intent={Intent.PRIMARY}
                  onClick={handleUpdate}
                  disabled={isGeneratingCronExpression || item.status === ScheduleStatus.Activated}
                >
                  Update
                </Button>

                {item.status === ScheduleStatus.Deactivated && (
                  <span className="ml-3">
                    <Button intent={Intent.NONE} onClick={handleActivate} disabled={isGeneratingCronExpression}>
                      Activate
                    </Button>
                  </span>
                )}

                {item.status === ScheduleStatus.Activated && (
                  <span className="ml-3">
                    <Button intent={Intent.NONE} onClick={handleDeactivate} disabled={isGeneratingCronExpression}>
                      Deactivate
                    </Button>
                  </span>
                )}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
}
