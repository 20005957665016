import { Channel } from 'phoenix';
import { EventEmitter } from 'events';
import socket from '../socket';
import { hedgeSounds, wickcatcherSounds } from '../sounds';

import { HedgeColor, HedgeState, OpportunityRateLimitError, StrategyType, TradeFeedHedgeOrder, TradeFeedOrderFill } from './interfaces/bot';

const BLUE_FILTERS = [
  { minUsd: 5000, minPercent: 0.008 },
  { minUsd: 10000, minPercent: 0.006 },
  { minUsd: 20000, minPercent: 0.004 },
];

const RATE_LIMIT_ERRORS = ['exceed_weight_limit', 'exceed_order_second_limit', 'exceed_order_minute_limit', 'exceed_order_day_limit'];

// TDB should be in config var
const BIG_SLIP = 0.5;
const GREEN_MIN_USD_VALUE = 10;
const GREEN_MIN_BTC_VALUE = 0.0003;
const BIG_PROFIT_DOOR = 0.5;

class OrderService {
  public hedgeEvent = new EventEmitter();
  public updateActivePairEvent = new EventEmitter();
  public orderFills: TradeFeedOrderFill[] = [];
  private opportunitiesExceedVolumeThreshold: number[] = [];
  public ordersSkipHedging: { trading_pair_id: number; opportunity_id: number }[] = [];
  private opportunitiesExceedRateLimit: OpportunityRateLimitError[] = [];

  public subscribeTradeFeed(tradeFeedChannel: React.MutableRefObject<Channel | null>) {
    tradeFeedChannel.current = socket.channel(`trade_feed:lobby`, {});

    tradeFeedChannel.current
      ?.join()
      .receive('ok', () => {
        console.log(`[TradeFeed] Joined "trade_feed:lobby" channel for trade feed real-time updates`);
      })
      .receive('error', (resp: any) => {
        console.log('Unable to join', resp);
      });

    // Populate data with latest ones coming from WebSocket
    tradeFeedChannel.current?.on('primary:order_fill:init', ({ data }: any) => {
      this.handlePrimaryOrderFill('primary:order_fill:init', data);
    });

    tradeFeedChannel.current?.on('primary:order_fill:update', ({ data }: any) => {
      this.handlePrimaryOrderFill('primary:order_fill:update', data);
    });

    tradeFeedChannel.current?.on('hedging:order:init', ({ data }: any) => {
      this.handleHedging('hedging:order:init', data);
    });

    tradeFeedChannel.current?.on('hedging:order:update', ({ data }: any) => {
      this.handleHedging('hedging:order:update', data);
    });

    tradeFeedChannel.current?.on('hedging:order:ws_update', ({ data }: any) => {
      this.handleHedging('hedging:order:ws_update', data);
    });

    tradeFeedChannel.current?.on('retry:init', ({ data }: any) => {
      this.handleHedgeRetry('retry:init', data);
    });

    tradeFeedChannel.current?.on('retry:hedging', ({ data }: any) => {
      this.handleHedgeRetry('retry:hedging', data);
    });

    tradeFeedChannel.current?.on('retry:failed_and_exceeded_max_retries', ({ data }: any) => {
      this.handleHedgeRetry('retry:failed_and_exceeded_max_retries', data);
    });

    tradeFeedChannel.current?.on('retry:failed_with_non_retryable_error', ({ data }: any) => {
      this.handleHedgeRetry('retry:failed_with_non_retryable_error', data);
    });

    tradeFeedChannel.current?.onClose(() => {
      console.log('Left "trade_feed:lobby" channel');
    });
  }

  public unsubscribeTradeFeed(tradeFeedChannel: React.MutableRefObject<Channel | null>) {
    tradeFeedChannel.current?.leave();
  }

  public clearNewFillOrders() {
    this.orderFills = [];
  }

  public handlePrimaryOrderFill(action: string, order: TradeFeedOrderFill) {
    if (this.orderFills.findIndex((o) => o.id === order.id) < 0) {
      this.orderFills.push(order);
    }

    // For Wick catcher order fills, we plays a sound even for primary order fills, regardless if it hedges on secondary or not
    if (order.trading_pair.strategy_type === StrategyType.WickCatcher_Standard) {
      const alertAudio = wickcatcherSounds.primaryFilled;

      alertAudio.volume = 1;
      alertAudio.play().then();
    }

    this.checkAndAlertHedgedOrder(order);
    this.hedgeEvent.emit(action, order);
  }

  public handleHedging(action: string, order: TradeFeedOrderFill) {
    this.hedgeEvent.emit(action, order);
    return order;
  }

  public handleHedgeRetry(action: string, order: TradeFeedHedgeOrder) {
    this.hedgeEvent.emit(action, order);
  }

  public handleOpportunityExceedVolumeThreshold(opportunityId: number) {
    if (this.opportunitiesExceedVolumeThreshold.indexOf(opportunityId) < 0) {
      this.opportunitiesExceedVolumeThreshold.push(opportunityId);
    }
  }

  public isBigVolumeOpportunity(opportunityId: number) {
    return this.opportunitiesExceedVolumeThreshold.indexOf(opportunityId) >= 0;
  }

  public removeOpportunityExceedVolumeThreshold(opportunityIds: number[]) {
    this.opportunitiesExceedVolumeThreshold = this.opportunitiesExceedVolumeThreshold.filter((opp) => opportunityIds.indexOf(opp) < 0);
  }

  public handleSkipHedging(tradingPairId: number, opportunityId: number) {
    if (this.ordersSkipHedging.findIndex((o) => o.trading_pair_id === tradingPairId && o.opportunity_id === opportunityId) < 0) {
      this.ordersSkipHedging.push({
        trading_pair_id: tradingPairId,
        opportunity_id: opportunityId,
      });
    }
  }

  public isSkippedHedging(tradingPairId: number, opportunityId: number) {
    return this.ordersSkipHedging.findIndex((o) => o.trading_pair_id === tradingPairId && o.opportunity_id === opportunityId) >= 0;
  }

  public isBlueOrder(order: TradeFeedOrderFill) {
    const target = order.opportunity.percentage;

    if (!order.achieved || !order.last_fill_value) {
      return false;
    }

    const { amount, currency } = order.last_fill_value;

    if (currency !== 'USDT' && currency !== 'BUSD') {
      return false;
    }

    return (
      BLUE_FILTERS.filter((condition) => {
        if (this.isPairTradingOrder(order)) {
          return amount > condition.minUsd && order.achieved > condition.minPercent * order.opportunity.ratio;
        } else if (this.isSellOrder(order)) {
          return amount > condition.minUsd && order.achieved > condition.minPercent * target;
        } else {
          return amount > condition.minUsd && order.achieved < condition.minPercent * target;
        }
      }).length > 0
    );
  }

  public isSweetOrder(order: TradeFeedOrderFill) {
    const ec = order.opportunity.emergency_cancel;
    let achieved;
    if (order.opportunity.ratio > 0) {
      achieved = ((order.achieved - order.opportunity.ratio) / order.opportunity.ratio) * 100;
    } else {
      achieved = order.achieved;
    }

    if (!achieved || !ec || !order.last_fill_value) {
      return false;
    }

    const { amount, currency } = order.last_fill_value;

    // To be highlighted as green, a sweet order's value must first bigger than a USDT/BTC threshold
    if ((currency == 'USDT' && amount < GREEN_MIN_USD_VALUE) || (currency == 'BTC' && amount < GREEN_MIN_BTC_VALUE)) {
      return false;
    }

    if (this.isSellOrder(order)) {
      return achieved > BIG_PROFIT_DOOR + ec;
    } else {
      return ec - BIG_PROFIT_DOOR > achieved;
    }
  }

  public isUnprofitableOrder(order: TradeFeedOrderFill) {
    const ec = order.opportunity.emergency_cancel;
    let achieved;
    if (order.opportunity.ratio > 0) {
      achieved = ((order.achieved - order.opportunity.ratio) / order.opportunity.ratio) * 100;
    } else {
      achieved = order.achieved;
    }

    if (!achieved || !ec) {
      return false;
    }

    if (this.isSellOrder(order)) {
      return ec - BIG_SLIP > achieved;
    } else {
      return achieved > BIG_SLIP + ec;
    }
  }

  public onUpdateActiveTradingPairs(pairId: number) {
    this.updateActivePairEvent.emit('active_pairs:update', pairId);
  }

  public isRateLimitError(error: string) {
    return RATE_LIMIT_ERRORS.indexOf(error) >= 0;
  }

  public handleOpportunityExceedRateLimit(opportunityError: OpportunityRateLimitError) {
    this.opportunitiesExceedRateLimit.push(opportunityError);
  }

  public getOrderRateLimitError(opportunityId: number) {
    return this.opportunitiesExceedRateLimit.find((oppo) => oppo.opportunityId === opportunityId);
  }

  public clearOpportunityExceedRateLimit(opportunityIds: number[]) {
    if (!opportunityIds || opportunityIds.length === 0) {
      return;
    }

    this.opportunitiesExceedRateLimit = this.opportunitiesExceedRateLimit.filter((oppo) => opportunityIds.indexOf(oppo.opportunityId) < 0);
  }

  private isSellOrder(order: TradeFeedOrderFill) {
    return order.side === 'sell';
  }

  private isPairTradingOrder(order: TradeFeedOrderFill) {
    return order.opportunity.ratio > 0;
  }

  private checkAndAlertHedgedOrder(order: TradeFeedOrderFill) {
    const hedgeOrder = order.hedge_orders.sort((o1, o2) => +new Date(o2.init_bot_ts) - +new Date(o1.init_bot_ts))[0];
    if (hedgeOrder && hedgeOrder.hedge_state === HedgeState.Finished) {
      order = this.updateColor(order);
      this.playSound(order);
    }
  }

  private updateColor(order: TradeFeedOrderFill) {
    if (this.isUnprofitableOrder(order)) {
      order.color = HedgeColor.Orange;
    } else if (this.isSweetOrder(order)) {
      order.color = HedgeColor.Green;
    } else if (this.isBlueOrder(order)) {
      order.color = HedgeColor.Blue;
    } else {
      order.color = HedgeColor.White;
    }
    return order;
  }

  private playSound(order: TradeFeedOrderFill) {
    if (order.color && order.color !== HedgeColor.Gray && order.color !== HedgeColor.Danger) {
      const hedgeSound = hedgeSounds[order.color];
      hedgeSound.volume = 1;
      hedgeSound.play().then();
    }
  }
}

const orderService = new OrderService();
export default orderService;
