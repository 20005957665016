import { ScheduleList } from '../../components/schedule/list/list';

export const SchedulesIndexPage = (props: any) => {
  return (
    <>
      <h2 className="text-lg font-bold my-1">Schedules</h2>

      <ScheduleList />
    </>
  );
};
