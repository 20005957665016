import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import axios from '../../../../shared/custom-axios';
import { Button, Classes, Dialog } from '@blueprintjs/core';

import { LagTimeEvent, TradeFeedHedgeOrder, TradeFeedOrderFill } from '../../../../shared/interfaces/bot';
import { TimezoneContext } from '../../../../contexts/timezone';
import { TradeFeedOrderFillLagTimeDetailsTable } from './table';
import { BotsManagerContext } from '../../../../contexts/bots-manager';

interface Props {
  isDeFiTaking: boolean;
  orderFill: TradeFeedOrderFill;
}

export function TradeFeedOrderFillLagTimeDetails({ isDeFiTaking, orderFill }: Props) {
  const { currentBot } = useContext(BotsManagerContext);
  const { timeFormater } = useContext(TimezoneContext);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [events, setEvents] = useState<LagTimeEvent[]>([]);
  const [referenceEvent, setReferenceEvent] = useState<LagTimeEvent>();
  const [hedgeOrders, setHedgeOrders] = useState<TradeFeedHedgeOrder[]>([]);

  const reloadHedgeOrders = useCallback(
    (item: TradeFeedOrderFill) => {
      const path = `/api/trade_feed/order_fills/${item.id}/?trading_pair_id=${item.trading_pair_id}&bot_id=${currentBot?.id}`;
      const url = `${path}`;

      axios.get<{ data: TradeFeedOrderFill }>(url).then((response) => {
        setHedgeOrders(response.data.data?.hedge_orders);
      });
    },
    [currentBot],
  );

  useEffect(() => {
    if (!isOpenDetails) {
      return setHedgeOrders([]);
    }

    reloadHedgeOrders(orderFill);
  }, [isOpenDetails, orderFill, reloadHedgeOrders]);

  useEffect(() => {
    if (!isOpenDetails) {
      return;
    }

    let primaryEvents = getPrimaryEvents(orderFill);
    let hedgeEvents = getHedgeEvents(hedgeOrders);
    let events = [...primaryEvents, ...hedgeEvents];

    if (referenceEvent) {
      // If user selected an event to use as base event
      // We calculate each event's diff_time by taking the difference between its timestamp and the base event's
      if (referenceEvent.timestamp) {
        let baseUnixTime = +new Date(referenceEvent.timestamp);

        events = events.map((event) => {
          if (event.timestamp) {
            let thisUnixTime = +new Date(event.timestamp);
            let diff_time = thisUnixTime - baseUnixTime;

            return { ...event, diff_time };
          }

          return event;
        });
      }
    } else {
      // If user did not select any event to use as base event
      // We calculate each event's diff_time by taking the difference between its timestamp and its previous event's
      events = events.map((event, index, array) => {
        if (event.timestamp) {
          let prevEvent = index === 0 ? event : array[index - 1];

          if (prevEvent.timestamp) {
            let baseUnixTime = +new Date(prevEvent.timestamp);
            let thisUnixTime = +new Date(event.timestamp);
            let diff_time = thisUnixTime - baseUnixTime;

            return { ...event, diff_time };
          }

          return event;
        }

        return event;
      });
    }

    setEvents(events);
  }, [isOpenDetails, orderFill, hedgeOrders, referenceEvent]);

  const columns = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Event name',
        accessor: 'name',
      },
      {
        id: 'timestamp',
        Header: 'Timestamp',
        accessor: (item: LagTimeEvent, _rowIndex: number) => {
          if (!item.timestamp) {
            return '';
          }

          const dateTime = new Date(item.timestamp + 'Z');

          if (referenceEvent && referenceEvent.id === item.id) {
            return `${timeFormater.format(dateTime)}`;
          }

          if (_.isNumber(item.diff_time)) {
            let diffTimeString = item.diff_time >= 0 ? `(+${item.diff_time})` : `(${item.diff_time})`;

            return (
              <>
                <span className="text-pink-600 mr-1">{diffTimeString}</span> {timeFormater.format(dateTime)}
              </>
            );
          }

          return `${timeFormater.format(dateTime)}`;
        },
      },
    ],
    [timeFormater, referenceEvent],
  );

  return (
    <div
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span
        className="cursor-pointer underline"
        onClick={() => {
          setIsOpenDetails(true);
        }}
      >
        {isDeFiTaking ? orderFill?.total_lag_time : orderFill?.lag_time}
      </span>

      <div
        onDoubleClick={() => {
          setReferenceEvent(undefined);
        }}
      >
        <Dialog
          isOpen={isOpenDetails}
          onClose={() => {
            setIsOpenDetails(false);
          }}
          title="Lag Time Details"
          style={{ minWidth: '600px' }}
        >
          <div className={Classes.DIALOG_BODY}>
            <div className="">
              <p>Secondary lag time: {orderFill.lag_time ? `${orderFill.lag_time}(ms)` : ''}</p>
              <p>Total lag time: {orderFill.total_lag_time ? `${orderFill.total_lag_time}(ms)` : ''}</p>
              {events && (
                <TradeFeedOrderFillLagTimeDetailsTable
                  columns={columns}
                  data={events}
                  rowProps={(row) => ({
                    onDoubleClick: (e: any) => {
                      e.stopPropagation();
                      setReferenceEvent(row.original);
                    },
                    onClick: (e: any) => {
                      e.stopPropagation();
                    },
                  })}
                  selectedItem={referenceEvent}
                />
              )}

              <p className="mt-3 mb-0 text-xs">
                Double-click on an event to use it as an absolute reference for latency calculation. Double-click outside the table to get
                back to the default view.
              </p>

              <p className="mt-3 mb-0 text-xs italic">(*) Timestamps source: exchange, otherwise by Spread bot.</p>
            </div>
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                onClick={() => {
                  setIsOpenDetails(false);
                }}
                small={true}
              >
                Close
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
}

function getPrimaryEvents(orderFill: TradeFeedOrderFill): LagTimeEvent[] {
  let primaryOrderEvents = [
    {
      id: 'primary-rest-start',
      name: (
        <>
          Primary <span className="">REST</span> start
        </>
      ),
      timestamp: orderFill.rest_start_bot_ts,
      source: 'bot',
      type: 'order_fill',
    },
    {
      id: 'primary-rest-end',
      name: (
        <>
          Primary <span className="">REST</span> end
        </>
      ),
      timestamp: orderFill.rest_end_bot_ts,
      source: 'bot',
      type: 'order_fill',
    },
    {
      id: 'primary-ws-transaction-new',
      name: (
        <>
          Primary WS transaction <span className="font-medium text-yellow-500">new</span> *
        </>
      ),
      timestamp: orderFill.new_tx_ts,
      source: 'exchange',
      type: 'order_fill',
    },
    {
      id: 'primary-ws-event-new',
      name: (
        <>
          Primary WS event <span className="font-medium text-yellow-500">new</span> *
        </>
      ),
      timestamp: orderFill.new_tx_ts,
      source: 'exchange',
      type: 'order_fill',
    },
    {
      id: 'primary-ws-bot-new',
      name: (
        <>
          Primary WS bot <span className="font-medium text-yellow-500">new</span>
        </>
      ),
      timestamp: orderFill.new_bot_ts,
      source: 'bot',
      type: 'order_fill',
    },
    {
      id: 'primary-ws-transaction-filled',
      name: (
        <>
          Primary WS transaction <span className="font-medium text-green-500">filled</span> *
        </>
      ),
      timestamp: orderFill.fill_tx_ts,
      source: 'exchange',
      type: 'order_fill',
    },
    {
      id: 'primary-ws-event-filled',
      name: (
        <>
          Primary WS event <span className="font-medium text-green-500">filled</span> *
        </>
      ),
      timestamp: orderFill.fill_event_ts,
      source: 'exchange',
      type: 'order_fill',
    },
    {
      id: 'primary-ws-bot-filled',
      name: (
        <>
          Primary WS bot <span className="font-medium text-green-500">filled</span>
        </>
      ),
      timestamp: orderFill.fill_bot_ts,
      source: 'bot',
      type: 'order_fill',
    },
  ] as LagTimeEvent[];

  primaryOrderEvents.sort((a: LagTimeEvent, b: LagTimeEvent) => {
    let time_a = +new Date(a.timestamp) || Infinity;
    let time_b = +new Date(b.timestamp) || Infinity;

    if (time_a < time_b) {
      return -1;
    }

    if (time_a > time_b) {
      return 1;
    }

    return 0;
  });

  return primaryOrderEvents;
}

function getHedgeEvents(hedgeOrders: TradeFeedHedgeOrder[]): LagTimeEvent[] {
  let hedgeOrderEvents = hedgeOrders.flatMap((hedgeOrder, index) => {
    const hedgeUpdates = hedgeOrder.hedge_updates.map((hedgeUpdate, hedgeUpdateIndex) => {
      return {
        id: `hedge-update-${hedgeUpdateIndex + 1}`,
        name: (
          <>
            Hedge WS transaction #{hedgeUpdateIndex + 1}
            <span className="font-medium text-green-500"> {hedgeUpdate.status}</span> *
          </>
        ),
        timestamp: hedgeUpdate.transaction_time,
        source: 'exchange',
        type: 'hedge_update',
      };
    });

    return [
      {
        id: `hedge-order-start-${index + 1}`,
        name: <>{index + 1}. Hedge REST start</>,
        timestamp: hedgeOrder.rest_start_bot_ts,
        source: 'bot',
        type: 'hedge_order',
      },
      {
        id: `hedge-order-end-${index + 1}`,
        name: 'Hedge REST end',
        timestamp: hedgeOrder.rest_end_bot_ts,
        source: 'bot',
        type: 'hedge_update',
      },
      ...hedgeUpdates,
    ];
  }) as LagTimeEvent[];

  hedgeOrderEvents.sort((a: LagTimeEvent, b: LagTimeEvent) => {
    let time_a = +new Date(a.timestamp) || Infinity;
    let time_b = +new Date(b.timestamp) || Infinity;

    if (time_a < time_b) {
      return -1;
    }

    if (time_a > time_b) {
      return 1;
    }

    return 0;
  });

  return hedgeOrderEvents;
}
