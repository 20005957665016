import { useCallback } from 'react';
import axios from '../../shared/custom-axios';
import { useNavigate } from 'react-router-dom';
import { Intent } from '@blueprintjs/core';

import { Account } from '../../shared/interfaces/bot';
import { AppToaster } from '../../shared/app-toaster';
import { ScheduleForm, ScheduleFormState } from '../../components/schedule/form/form';

export const ScheduleNewPage = (props: any) => {
  let navigate = useNavigate();

  const handleCreate = useCallback(
    (formState: object) => {
      axios
        .post<Account[]>('/api/scheduled_tasks', { scheduled_task: formState })
        .then(() => {
          navigate('/schedules');
          const message = 'Schedule created successfully!';
          AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    },
    [navigate],
  );

  return (
    <>
      <h2 className="text-lg font-bold my-1">New schedule</h2>

      <ScheduleForm onCreate={handleCreate} />
    </>
  );
};
