import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { Button, Classes, Icon, Intent, Tag, TagProps } from '@blueprintjs/core';
import { Classes as PopoverClasses, Tooltip2 } from '@blueprintjs/popover2';

import axios from '../../../../shared/custom-axios';
import { AppToaster } from '../../../../shared/app-toaster';
import { HedgeState, HedgeTriggerType, TradeFeedHedgeOrder, TradeFeedOrderFill, TradingPair } from '../../../../shared/interfaces/bot';
import { copyToClipboard } from '../../../../shared/utils';
import { TimezoneContext } from '../../../../contexts/timezone';

import { TradeFeedHedgeOrderItem } from '../item/item';
import { TradeFeedHedgeOrdersListTable } from './table';
import { TradeFeedHedgeOrderManualRetryForm } from '../manual-retry/form';

interface Props {
  items: TradeFeedHedgeOrder[];
  orderFill?: TradeFeedOrderFill;
  onReloadItems?: (orderFill: TradeFeedOrderFill) => void;
  itemDetailsViewable?: boolean;
  orderNumbering?: 'index' | 'attempts_counter';
  tradingPair: TradingPair;
}

export function TradeFeedHedgeOrdersList({
  items,
  onReloadItems,
  orderFill,
  itemDetailsViewable = false,
  orderNumbering = 'attempts_counter',
  tradingPair,
}: Props) {
  const { dateFormater, timeFormater } = useContext(TimezoneContext);

  const [selectedItem, setSelectedItem] = useState<TradeFeedHedgeOrder | null>(null);
  const [manualRetryItem, setManualRetryItem] = useState<TradeFeedHedgeOrder | null>(null);

  const handleManualRetry = (order: TradeFeedHedgeOrder, params: any) => {
    console.log(order, 'ORDER');
    const data = Object.assign({}, params, {
      price: { amount: params.price, currency: order.price?.currency },
      volume: { amount: params.volume, currency: order.volume?.currency },
    });

    axios
      .post<TradeFeedHedgeOrder>(`/api/trade_feed/hedge_orders/${order.id}/manual_retry`, data)
      .then(() => {
        const message = 'Order is submitted to be retried';
        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });

        setManualRetryItem(null);

        orderFill && onReloadItems?.(orderFill);
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });

        orderFill && onReloadItems?.(orderFill);
      });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'No.',
        accessor: (order: TradeFeedHedgeOrder, rowIndex: number) => {
          let props: TagProps = {};

          switch (order.hedge_state) {
            case HedgeState.Hedging: {
              props = {
                intent: Intent.PRIMARY,
                minimal: true,
                fill: true,
                round: true,
                interactive: true,
              };

              break;
            }
            case HedgeState.Finished: {
              props = {
                intent: Intent.SUCCESS,
                minimal: false,
                fill: true,
                round: true,
                interactive: true,
              };

              break;
            }
            case HedgeState.Failed: {
              props = {
                intent: Intent.DANGER,
                minimal: false,
                fill: true,
                round: true,
                interactive: true,
              };

              break;
            }
            default: {
              props = {
                intent: Intent.NONE,
                minimal: true,
                fill: true,
                round: true,
                interactive: true,
              };

              break;
            }
          }

          return (
            <>
              {itemDetailsViewable && (
                <Tag onClick={() => setSelectedItem(order)} {...props} className="text-center select-none group block">
                  {orderNumbering === 'attempts_counter' && (
                    <span className="group-hover:hidden block">
                      {order.attempts_counter === 0 ? 'First try' : `Retry #${order.attempts_counter}`}
                    </span>
                  )}

                  {orderNumbering === 'index' && <span className="group-hover:hidden block">{rowIndex + 1}</span>}

                  <span className="group-hover:block hidden">View</span>
                </Tag>
              )}

              {!itemDetailsViewable && (
                <Tag {...props} className="text-center select-none">
                  {orderNumbering === 'attempts_counter' && (
                    <span>{order.attempts_counter === 0 ? 'First try' : `Retry #${order.attempts_counter}`}</span>
                  )}

                  {orderNumbering === 'index' && <span>{rowIndex + 1}</span>}
                </Tag>
              )}
            </>
          );
        },
        minWidth: 128,
        maxWidth: 128,
      },
      {
        Header: 'Hedge Time',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (order.rest_start_bot_ts) {
            const dateTime = new Date(order.rest_start_bot_ts + 'Z');

            return (
              <Tooltip2
                hoverOpenDelay={420}
                content="The time the bot was about to hedge (source: our bot)"
                placement="top"
                className={PopoverClasses.TOOLTIP2_INDICATOR}
              >
                {timeFormater.format(dateTime)}
              </Tooltip2>
            );
          }
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Batching',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (order.is_batch) {
            return order.child_orders.length;
          }

          return '-';
        },
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Side',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          return (
            <div className="text-center">
              {order.side == 'buy' && (
                <Tag minimal={true} intent={Intent.SUCCESS}>
                  {_.upperFirst(order.side)}
                </Tag>
              )}

              {order.side == 'sell' && (
                <Tag minimal={true} intent={Intent.DANGER}>
                  {_.upperFirst(order.side)}
                </Tag>
              )}
            </div>
          );
        },
        minWidth: 80,
        maxWidth: 80,
      },
      {
        Header: 'Trade ID',
        id: 'trade_id',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.trade_id} placement="top">
              <span className="cursor-pointer" onClick={() => order.trade_id && copyToClipboard(order.trade_id)}>
                {order.trade_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'External ID',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.external_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.external_id)}>
                {order.external_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Internal ID',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.internal_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.internal_id)}>
                {order.internal_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
        minWidth: 110,
        maxWidth: 110,
      },
      {
        Header: 'Price',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (!order.price?.amount) {
            return;
          }

          return `${order.price?.amount} ${order.price?.currency}`;
        },
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Volume',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (!order.volume?.amount) {
            return;
          }

          return (
            <>
              <span className="block">
                {order.volume?.amount} {order.volume?.currency}
              </span>

              <span className="block">
                {order.total_value?.amount ? `(${order.total_value?.amount} ${order.total_value?.currency})` : ''}
              </span>
            </>
          );
        },
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Avg Fill Price',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (!order.avg_fill_price?.amount) {
            return;
          }

          return `${order.avg_fill_price?.amount} ${order.avg_fill_price?.currency}`;
        },
        minWidth: 120,
        maxWidth: 120,
      },
      {
        Header: 'Auto-retryable',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (order.hedge_state !== HedgeState.Failed) {
            return;
          }

          if (order.non_retryable_reason) {
            return (
              <span className="block text-center">
                <Icon icon="delete" className="text-red-700" />
              </span>
            );
          }

          return (
            <span className="block text-center">
              <Icon icon="tick" className="text-green-600" />
            </span>
          );
        },
      },
      {
        Header: 'Trigger type',
        accessor: 'trigger_type',
      },
      {
        Header: '',
        id: 'action',
        accessor: (order: TradeFeedHedgeOrder, _rowIndex: number) => {
          if (order.hedge_state !== HedgeState.Failed) {
            return;
          }

          const autoRetryable = order.trigger_type === HedgeTriggerType.Auto && !order.non_retryable_reason;
          const alreadyManualRetried = !!order.manual_retried_at;
          const manualRetryable = !autoRetryable && !alreadyManualRetried;

          if (manualRetryable) {
            return (
              <span className="text-center block">
                <Button
                  onClick={() => setManualRetryItem(order)}
                  small={true}
                  icon="refresh"
                  intent={Intent.WARNING}
                  className={Classes.POPOVER_DISMISS}
                ></Button>
              </span>
            );
          }
        },
      },
    ],
    [dateFormater, timeFormater],
  );

  return (
    <>
      {items && (
        <TradeFeedHedgeOrdersListTable
          columns={columns}
          data={items}
          selectedItem={selectedItem}
          rowProps={(row) => ({
            onDoubleClick: () => itemDetailsViewable && setSelectedItem(row.original),
          })}
        />
      )}

      {manualRetryItem && (
        <TradeFeedHedgeOrderManualRetryForm
          item={manualRetryItem}
          onSetItem={setManualRetryItem}
          onSubmit={handleManualRetry}
          orderFill={orderFill}
        />
      )}

      {selectedItem && (
        <div className="mt-5 overflow-auto">
          <div className="text-sm">
            <span>
              <Icon icon="nest"></Icon>{' '}
            </span>

            <span>
              Viewing {selectedItem.attempts_counter === 0 ? 'first try order' : `#${selectedItem.attempts_counter} retry order`}{' '}
            </span>

            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setSelectedItem(null);
              }}
              className="ml-1 text-xs"
            >
              (Close)
            </a>
          </div>

          <div className="mt-3">
            <TradeFeedHedgeOrderItem item={selectedItem} orderFill={orderFill} tradingPair={tradingPair} />
          </div>
        </div>
      )}
    </>
  );
}
