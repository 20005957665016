export enum ScheduleOccurrence {
  OneTime = 'one_time',
  Recurring = 'recurring',
}

export enum ScheduleStatus {
  Activated = 'activated',
  Deactivated = 'deactivated',
}

export interface Schedule {
  id: number;
  status: ScheduleStatus;
  name: string;
  occurrence: ScheduleOccurrence;
  run_once_at: string;
  run_at_prompt: string;
  cron_expression: string;
  timezone: string;
  actions: TaskAction[];
  next_executions: string[];
}

export interface TaskActivation {
  id: number;
  occurrence: ScheduleOccurrence;
  run_once_at: string;
  run_at_prompt: string;
  cron_expression: string;
  timezone: string;
  activated_at: string;
  deactivated_at: string;
  actions: TaskAction[];
  executions: TaskExecution[];
}

export interface TaskExecution {
  id: number;
  actions: TaskAction[];
  executed_at: string;
}

export interface SchedulesListResponse {
  data: Schedule[];
}

export interface ScheduleResponse {
  data: Schedule;
}

export enum TaskActionType {
  NotifyTerminal = 'notify_terminal',
  SendWebHook = 'send_webhook',
  StartPair = 'start_pair',
  StopPair = 'stop_pair',
  StartWick = 'start_wick',
  StopWick = 'stop_wick',
  ResumeAgainstExchangesDiffs = 'resume_against_exchanges_diffs',
  PauseAgainstExchangesDiffs = 'pause_against_exchanges_diffs',
}

export interface NotifyTerminalTaskActionParams {
  message: string;
}

export interface SendWebHookTaskActionParams {
  webhook_url: string;
}

export enum StartStopSideTaskAction {
  Short = 'short',
  Long = 'long',
  Both = 'both',
}

export interface StartStopPairAndWickTaskActionParams {
  ids: string;
  side: StartStopSideTaskAction;
}

export enum ExchangesDiffsConditionListing {
  Listed = 'listed',
  NotListed = 'not_listed',
}

export interface MarketAvailabilityRule {
  listing: ExchangesDiffsConditionListing;
  quote_currency: string;
  main_exchange: string;
}

export enum ScheduleWebhookAuthType {
  None = 'none',
  APIKey = 'api_key',
}

export interface ScheduleWebhook {
  url: string;
  auth_type: ScheduleWebhookAuthType;
  api_key?: string;
}

export interface PauseAgainstExchangesDiffsTaskActionParams {
  market_availability_rules: MarketAvailabilityRule[];
  trading_pair_ids: string;
  wick_catcher_ids: string;
  exceptional_currencies: string;
  webhook_urls: string;
  webhooks: ScheduleWebhook[];
  side: StartStopSideTaskAction;
}

export interface ResumeAgainstExchangesDiffsTaskActionParams extends PauseAgainstExchangesDiffsTaskActionParams {
  pause_schedule_id?: string;
}

export type TaskActionParamsMap = {
  [TaskActionType.NotifyTerminal]: NotifyTerminalTaskActionParams;
  [TaskActionType.SendWebHook]: SendWebHookTaskActionParams;
  [TaskActionType.StartPair]: StartStopPairAndWickTaskActionParams;
  [TaskActionType.StopPair]: StartStopPairAndWickTaskActionParams;
  [TaskActionType.StartWick]: StartStopPairAndWickTaskActionParams;
  [TaskActionType.StopWick]: StartStopPairAndWickTaskActionParams;
  [TaskActionType.ResumeAgainstExchangesDiffs]: ResumeAgainstExchangesDiffsTaskActionParams;
  [TaskActionType.PauseAgainstExchangesDiffs]: PauseAgainstExchangesDiffsTaskActionParams;
};

export type TaskAction = {
  [K in TaskActionType]: {
    type: K;
    params: TaskActionParamsMap[K];
    result: { [key: string]: any };
  };
}[TaskActionType];
